// basic.autosuggest.js
import React, { useState, useRef } from 'react';
import Autosuggest from 'react-autosuggest';
import $ from 'jquery'
// import './autosuggest.css';
// import { getLable } from './../utilites/Store';
const SelectClient = ({ clients, title, required, setClients, max = 6, type = 0, placeholder, error }) => {
    // type: 0 client 1 driver 2 edit 3 admin 4 all
    const [value, setValue] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const inputRef = useRef(null)

    // Trigger suggestions
    const getSuggestionValue = ({ Id, UserName, Name }) => Name + "(" + UserName + ")";
    // Render Each Option
    const renderSuggestion = ({ Name, UserName, Address, PhoneNumber, IdentityNumber, BankName, IBANNumber }) => (
        // u.IBANNumber, u.BankName, u.IdentityNumber
        <div className='rtl small row'>
            <div className="col-6">الإسم <b className='mx-2'>{Name}</b></div>
            <div className="col-6">الايميل <b className='mx-2'>{UserName}</b></div>
            {PhoneNumber && <div className="col-6">هاتف <b className='mx-2'>{PhoneNumber}</b></div>}
            {Address && <div className="col-6">العنوان <b className='mx-2'>{Address}</b></div>}
            {type === 5 && <>
                {IdentityNumber && <div className="col-6">الرقم الوطني <b className='mx-2'>{IdentityNumber}</b></div>}
                {BankName && <div className="col-6">البنك <b className='mx-2'>{BankName}</b></div>}
                {IBANNumber && <div className="col-6">رقم البنك <b className='mx-2'>{IBANNumber}</b></div>}
            </>}
        </div>
    );
    // OnChange event handler
    const onChange = (event, { newValue }) => {
        setValue(newValue)
    }
    // Suggestion rerender when user types
    const onSuggestionsFetchRequested = ({ value }) => {
        // if(!value.length>4)return;
        $.ajax({ url: '/api/user/search', data: { value, type } }).then(data => {
            setSuggestions(data);
        }).catch(() => {
            setSuggestions([]);
        })
        // getSuggestions(value).then(data=>{
        //     setSuggestions(data);
        // })
        // setSuggestions(getSuggestions(value))
    };
    // Triggered on clear
    const onSuggestionsClearRequested = () => {
        setSuggestions([])
        // if(!client) setValue('');
    }
    const onSuggestSelected = (event, { suggestion }) => {
        if (!clients.find(c => c.Id === suggestion.Id)) {
            setClients([...clients, suggestion]);
        }
        setValue(suggestion.Name)
        // setValue('');
    }

    const onKeyDown = (event) => {
        // console.log(event);
    }

    const inputProps = React.useMemo(() => ({
        placeholder,
        onChange,
        onKeyDown,
        ref: inputRef
    }), []);
    // Adding AutoSuggest component
    return (
        <>
            <div className="form-group position-relative">
                {title && <label className={(required ? 'required' : "")}>{title}</label>}
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    onSuggestionSelected={onSuggestSelected}
                    renderSuggestion={renderSuggestion}
                    inputProps={{ ...inputProps, value, disabled: (clients.length === max) }}
                />
                {clients.length === max && <span className='btn btn-sm btn-danger btn-round position-absolute px-3' style={{ bottom: 6, zIndex: 10, left: 10 }} onClick={() => {
                    setClients([]);
                    setValue('');
                    setTimeout(() => {
                        $(inputRef.current).focus()
                    }, 200)
                }}>&times;</span>}
                {error && <div className="text-danger show">
                    {error}
                </div>}
            </div>

        </>
    );
}

export default SelectClient