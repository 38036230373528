import * as Yup from "yup";
import { validateIBAN } from "./validateIBAN";

export const phoneNumber = Yup.string().matches(
  /^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
  "رقم الهاتف غير صحيح"
);
export const requiredPhoneNumber = phoneNumber.required("وش رقم تلفونك؟");

export const idNumber = Yup.string()
  .max(10, "لايمكن ان يكون رقم الهوية اكثر من 10 ارقام")
  .matches(/^[0-9]{10}$/gm, "لايبدو رقم الهوية صحيح");
export const requiredIdNumber = Yup.string()
  .required("لايمكن رقم الهوية فاغاً")
  .max(10, "لايمكن ان يكون رقم الهوية اكثر من 10 ارقام")
  .matches(/^[0-9]{10}$/gm, "لايبدو رقم الهوية صحيح");

export const ibanNumber = Yup.string().test(
  "is-valid",
  "لايبدو الرقم صالح",
  (value) => !value || value?.trim().length === 0 || validateIBAN(value)
);
export const requiredIbanNumber = Yup.string()
  .required("رقم الـ IBAN لايمكن ان يكون فارغاً")
  .test("is-valid", "لايبدو الرقم صالح", (value) => validateIBAN(value));
