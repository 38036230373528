import React from 'react'
import DHead from '../components/DHead'
import DefaultTable from './../components/DTable';
import * as Yup from "yup"
import FormInput from './../components/FormInput';

// validation schema
const validationSchema = Yup.object().shape({
    Name: Yup.string().required("لايمكن ان يكون الاسم فارغا"),
    Price: Yup.number("يجب ان يكون السعر رقما").min(0, "المبلغ لايجب ان يكون اقل من صفر").required("لايمكن ان يكون السعر فارغا"),
})

// table columns
const columns = [
    { Header: "الأسم", accessor: 'Name', isSorted: true },
    { Header: "السعر", accessor: 'Price' },
]

const FormContent = () => {
    return (
        <>
            <div className="col-12 col-md-6">
                <FormInput required propName="Name" title="الأسم" />
            </div>
            <div className="col-12 col-md-6">
                <FormInput required propName="Price" title="السعر" />
            </div>
        </>
    )
}

export default function OrderTypes() {
    return (

        <DHead title="انواع الطلبات">
            <DefaultTable
                columns={columns}
                url="/api/ordertype"
                schema={validationSchema}
                FormContent={FormContent}
                defaultValues={{ Id: 0, Name: '', Price: "0.00" }}
                editorProps={{
                    size: "md"
                }}
            />
        </DHead>
    )
}
