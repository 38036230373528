import React from 'react'

export default function JustInput({ containerClassName, title, required, invalid, subText, ...etc }) {
    return (
        <div className={`form-group ${containerClassName ?? ''}`}>
            <label className={required ? "required" : ""}>{title}</label>
            <input className={`form-control ${invalid ? " invalid" : ""}`} {...etc} />
            {subText && <span className='d-block mt-1'>{subText}</span>}
            {invalid && <div className="invalid-feedback">
                {invalid}
            </div>}
        </div>
    )
}
