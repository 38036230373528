import React from 'react'
import MadeWithLove from './MadeWithLove'

export default function CopyRightsText({ showMadeWith = true, ...etc }) {
  return (
    <div {...etc}>
      <span>جميع الحقوق محفوظة لـ سلفني {new Date().getFullYear()}&copy;</span>
      {showMadeWith && <>
        <br />
        <MadeWithLove />
      </>}
    </div>
  )
}
