import { useFormikContext } from 'formik'
import React, { useMemo } from 'react'

export default function FormSelect({ propName, required, inputProps, containerClassName, title, type = "text", children, ...etc }) {

    const { isSubmitting, values, handleChange, errors } = useFormikContext();
    const invalid = useMemo(() => errors && errors[propName], [errors])
    return (
        <div className={`form-group ${containerClassName ?? ''}`}>
            <label className={required ? "required" : ""}>{title}</label>
            <select value={values[propName] ?? ""} disabled={isSubmitting} onChange={handleChange(propName)} type={type} className={`form-control${invalid ? " invalid" : ""}`} {...inputProps} {...etc}>
                {children}
            </select>
            {invalid && <div className="invalid-feedback">
                {errors[propName]}
            </div>}

        </div>
    )
}