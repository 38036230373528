import { useFormikContext } from 'formik'
import React, { useMemo } from 'react'

export default function FormTextArea({ propName, required, inputProps, containerClassName, title, subText, children, ...etc }) {
    const { isSubmitting, values, handleChange, errors } = useFormikContext();
    const navigator = useMemo(() => propName ?? title, [])
    const invalid = useMemo(() => errors && errors[navigator], [errors])
    return (
        <div className={`form-group ${containerClassName ?? ''}`}>
            <label className={required ? "required" : ""}>{title}</label>
            <textarea disabled={isSubmitting} value={values[navigator] ?? ""} className={`form-control${invalid ? " invalid" : ""}`} {...inputProps} {...etc} onChange={handleChange(navigator)}></textarea>
            {subText && <span className='d-block mt-1'>{subText}</span>}
            {invalid && <div className="invalid-feedback">
                {errors[navigator]}
            </div>}
            {children}
        </div>
    )
}
