import React, { useEffect, useState } from 'react'
import $ from "jquery"
import * as Yup from "yup"
import BModel from './BModel';
import iziToast from 'izitoast';
import { requiredPhoneNumber } from '../utilities/validations';
import { Formik } from 'formik';
import FormInputWithIcon from '../components/FormInputWithIcon';
import FormInput from '../components/FormInput';
import IBANInput from '../components/IBANInput';
import { getBankByIBAN, validateIBAN } from '../utilities/validateIBAN';
import FormSelect from '../components/FormSelect';
const addModelValidation = Yup.object().shape({
    OrderTypeId: Yup.number().required("يجب إختيار نوع قيمة التمويل"),
    IBANNumber: Yup.string().required("رقم الـ IBAN لايمكن ان يكون فارغاً").test("is-valid", "لايبدو الرقم صالح", value => validateIBAN(value)),
    BankName: Yup.string().required("يجب تسجيل اسم البنك"),
    PhoneNumber: requiredPhoneNumber,
    RepresentitveCode: Yup.string().max(8, "اقصى عدد للأحرف هو 8")
    // IdentityNumber: requiredIdNumber,
    // Address:Yup.string().required(""),
    // City:Yup.string().required(""),
})
export default function ClientAddOrder({ show, setShow, callback }) {
    const [userData, setUserData] = useState(null)
    const [oT, setOT] = useState([]);
    useEffect(() => {
        $.ajax("/api/order/getclientinfo").then(({ client, types }) => {
            setUserData(client);
            setOT(types);
        }).catch(() => {
            setShow(null);
            iziToast.warning({
                message: "لم نستطع جلب بعض البيانات. الرجاء التأكد من اتصال الانترنت والمحاولة لاحقا"
            })
        }).always(() => {
            // setLoading(false)
        })
    }, [])
    if (!userData) {
        // setLoading(true);
        return null;
    }
    return (
        <Formik
            validationSchema={addModelValidation}
            initialValues={(() => {
                return JSON.parse(JSON.stringify(userData))
            })()}
            onSubmit={(values, { setSubmitting }) => {
                $.ajax({
                    url: "/api/order/placeclientorder",
                    method: "POST",
                    data: values
                }).then(() => {
                    iziToast.success({
                        message: "تم إنشاء الطلب بنجاح. سوف يتم مراجعة الطلب والتواصل معك إن احتجنا اي معلومات إضافية.",
                        position: "center"
                    })
                    setShow(null);
                    if (callback) callback(true);
                }).catch(() => {
                    iziToast.error({
                        message: "لم نستطع إنشاء هذا الطلب. الرجاء المحاولة لاحقاً"
                    })
                }).always(() => {
                    setSubmitting(false);
                })
            }}
        >
            {({ handleSubmit, errors, values }) => (
                <BModel show={show} setShow={() => setShow(null)} title="طلب تمويل جديد" okText="قدم الطلب" cancelText="إلغاء" handleSubmit={handleSubmit} >
                    {/* <p className='text-center font-weight-bold mb-0'>طلب تمويل اصغر بقيمة {show.Price} ريال سعودي</p> */}
                    <p className='text-center text-danger'>الرجاء التأكد من صحة المعلومات التالية من ثم قم بتقديم الطلب</p>
                    <div className="row">

                        <FormSelect containerClassName="col-md-6" propName="OrderTypeId" required title="قيمة التمويل">
                            <option></option>
                            {oT.map(o => <option key={o.Id} value={o.Id}>{o.Name}</option>)}
                        </FormSelect>
                        <FormInput title="كود المندوب" propName="RepresentitveCode" containerClassName="col-md-6" maxLength={8} />
                        <div className="col-12"></div>
                        <FormInputWithIcon title="رقم الهاتف" maxLength={9} required className="no-spinner text-left" containerClassName="col-md-6" prepend="+966" propName="PhoneNumber" disabled />
                        <FormInput title="رقم الهوية | الإقامة" disabled propName="IdentityNumber" containerClassName="col-md-6" maxLength={10} required />
                        <IBANInput containerClassName="col-md-6" required />
                        <FormInput title="إسم البنك" propName="BankName" required containerClassName="col-md-6" maxLength={200}>
                            <ValidateBankName errors={errors} values={values} />
                        </FormInput>
                        <FormInput title="العنوان" propName="Address" maxLength={150} containerClassName="col-md-6" />
                        <FormInput title="المدينة" propName="City" containerClassName="col-md-6" maxLength={70} />
                        <FormInput title="المهنة" propName="Occupation" containerClassName="col-md-6" maxLength={200} />
                        <FormInput title="جهة العمل" propName="WorkPlace" containerClassName="col-md-6" maxLength={200} />
                    </div>
                </BModel>
            )}
        </Formik>
    )
}


const ValidateBankName = ({ errors, values }) => {
    if (errors["BankName"] || errors["IBANNumber"] || !values.IBANNumber) return null;
    // get bank info
    const b = getBankByIBAN(values.IBANNumber);
    if (b && b.nameAr != values.BankName) {
        return <span className='d-block mt-1 small text-warning'>بناء على رقم الـ IBAN فأسم البنك يجب ان يكون  <b className='text-primary'>{b.nameAr}</b></span>
    }
    return null;
}