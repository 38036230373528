import React, { useEffect, useMemo, useState } from 'react'
import DHead from '../components/DHead'
import DefaultTable from './../components/DTable';
import * as Yup from "yup"
import FormInput from './../components/FormInput';
import BModel from '../components/BModel';
import $ from "jquery"
import LoadingSpinner from "../components/LoadingSpinner"
import { DateTimeArabic, GroupArrayByYearMonth, StatusTheme, orderStatus } from '../utilities/functions';


// validation schema
const validationSchema = Yup.object().shape({
    Name: Yup.string().required("لايمكن ان يكون الاسم فارغا").max(100, "لايمكن ان يكون يتخطى عدد الاحرف 100 حرف"),
    Code: Yup.string().required("لايمكن ان يكون الكود فارغا").max(8, "لايمكن ان يكون عدد احرف الكود اكثر من ثمانية"),
    Phone: Yup.string().required("لايمكن ان يكون رقم الهاتف فارغا").max(10, "لايمكن ان يكون عدد احرف الكود اكثر من 10 ارقام"),
    Commition: Yup.number("يجب ان تكون القيمة رقمية").min(0, "اقل مبلغ لايمكن ان يكون اقل من صفر").max(100, "لايمكن ان تكون العمولة اكثر من 100"),
})


const FormContent = () => {
    return (
        <>
            <div className="col-12 col-md-6">
                <FormInput required propName="Name" title="الأسم" maxLength={100} />
            </div>
            <div className="col-12 col-md-6">
                <FormInput required propName="Code" title="الكود" />
            </div>
            <div className="col-12 col-md-6">
                <FormInput required propName="Phone" title="رقم الهاتف" />
            </div>
            <div className="col-12 col-md-6">
                <FormInput propName="Commition" title="العمولة" type="number" />
            </div>
        </>
    )
}
//"Id", "Name", "Code", "Phone", "Commition" 
export default function Representitive() {
    const [report, setReport] = useState(null)
    const columns = useMemo(() => {
        return [
            { Header: "الأسم", accessor: 'Name', isSorted: true },
            { Header: "الكود", accessor: 'Code' },
            { Header: "الهاتف", accessor: 'Phone' },
            { Header: "العمولة", accessor: 'Commition' },
            {
                Header: 'الإحصائيات', disableSortBy: true, accessor: 'IDX', Cell: ({ value, row }) => {
                    return <>
                        <div className="btn-group float-right"><button onClick={() => setReport(row.original)} type="button" className="btn btn-sm btn-success">فتح إحصائيات المندوب</button></div>
                    </>
                }
            }
        ]
    }, [])
    return (
        <>
            <DHead title="المندوبين">
                <DefaultTable
                    columns={columns}
                    url="/api/representitive"
                    schema={validationSchema}
                    FormContent={FormContent}
                    defaultValues={{ Id: 0, Name: '', Code: "", Phone: "", Commition: 0 }}
                    editorProps={{
                        size: "md"
                    }}
                />
            </DHead>
            <Stastics setReport={setReport} report={report} />
        </>
    )
}

const monthNames = ["يناير", "فبراير", "مارس", "ابريل", "مايو", "يونيو", "يوليو", "اغسطس", "سبتمبر", "اكتوبر", "نوفمبر", "ديسمبر"];

const Stastics = ({ report, setReport }) => {
    const [data, setData] = useState(null);
    const com = useMemo(() => report?.Commition ?? 0, [report]);
    const groups = useMemo(() => {
        if (data && data.length > 0) return GroupArrayByYearMonth(data)
        return [];
    }, [])
    useEffect(() => {
        if (!report) return;
        $.ajax("/api/representitive/data?id=" + report?.Id).then(resp => {
            setData(resp);
        }).catch(() => {
            setData([])
        })
    }, [report])
    if (!report) return null;
    return (
        <BModel size='xl' show={report} setShow={() => setReport(null)} title={`إحصائيات المندوب : ${report?.Name}`} cancelText="إغلاق">
            {data === null ? <LoadingSpinner /> : data.length === 0 ? <div className='text-center text-muted p-5'>لايوجد اي بيانات.</div> : <div className='table-responsive'>
                <table className='table table-sm table-striped'>
                    {/* <thead>
                        <tr>
                            <th>#المرجع</th>
                            <th>تاريخ الطلب</th>
                            <th>المبلغ</th>
                            <th>العمولة</th>
                            <th>حالة الطلب</th>
                        </tr>
                    </thead> */}
                    <tbody>
                        <CalculateMonth title="الإحصائيات الإجمالية للمندوب" data={data} />
                        <tr><td className="py-4"></td></tr>
                        {groups.map(item => (
                            <>
                                <tr className='border-bottom'><th colSpan={5} className="text-center bg-primary text-white" style={{ fontSize: '1.2em' }}>سنة {item.year}</th></tr>
                                {item.months.map(month => (
                                    <>
                                        <tr className='border-bottom'><th colSpan={5} className="text-center bg-primary text-white" style={{ fontSize: '1.1em' }}>شهر {monthNames[month.month]} - {item.year}</th></tr>
                                        <tr>
                                            <th>#المرجع</th>
                                            <th>تاريخ الطلب</th>
                                            <th>المبلغ</th>
                                            <th>العمولة</th>
                                            <th>حالة الطلب</th>
                                        </tr>
                                        {month.items.map(day => (
                                            <tr key={day.Id}>
                                                <td>{day.Id}</td>
                                                <td>{DateTimeArabic(day.Date)}</td>
                                                <td>{(day.Price).toFixed(2)}</td>
                                                <td>{com}</td>
                                                <td><div className={`badge badge-` + StatusTheme(day.Status)}>{orderStatus[day.Status]}</div></td>
                                            </tr>
                                        ))}
                                        <CalculateMonth data={month.items} />
                                    </>
                                ))}
                            </>
                        ))}
                    </tbody>
                </table>
            </div>}
        </BModel>
    )
}

const CalculateMonth = ({ title, data }) => {
    const { accpted, onprogress, rejected } = useMemo(() => {
        let accpted = 0, rejected = 0, onprogress = 0;
        if (data) {
            data.forEach(item => {
                switch (item.Status) {
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        onprogress += 1;
                        break;
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        accpted += 1;
                        break;
                    case 8:
                    case 9:
                        rejected += 1;
                }
            })
        }
        return {
            accpted, rejected, onprogress
        }
    }, [data]);
    return (
        <>
            {title && <tr className='border-bottom'><th colSpan={5} className="text-center bg-primary text-white" style={{ fontSize: '1.3em' }}>{title}</th></tr>}
            <tr className="border-top">
                <th>إجمالي الطلبات</th>
                <th>{data.length}</th>
                <th>إجمالي الطلبات المقبولة</th>
                <th>{accpted}</th>
                <th></th>
            </tr>
            <tr className="border-top border-bottom">
                <th>إجمالي الطلبات المرفوضة</th>
                <th>{rejected}</th>
                <th>إجمالي الطلبات قيد الدراسة</th>
                <th>{onprogress}</th>
                <th></th>
            </tr>

        </>
    )
}