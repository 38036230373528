import iziToast from "izitoast";
import $ from "jquery";
export const ShallowEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
};

export const consts = {
  user: undefined,
  /**
   *
   * @param {Array} rolesArray an array of strings
   * @returns
   */
  isInRole: (rolesArray) => {
    if (consts.user) {
      return rolesArray.includes(consts.user.roles[0]);
    }
    return false;
  },
  /**
   * It checkes wether user is Editor
   * @returns Returns true or false
   */
  isEditor: () => consts.isInRole(["Editor", "Admin", "Developer"]),
  /**
   * It checks wether user is an Admin
   * @returns Returns true or false
   */
  isAdmin: () => consts.isInRole(["Admin", "Developer"]),
  /**
   *
   */
  setShowSpinner: null,
};

export function ResizeImages(file, complete) {
  // read file as dataUrl
  ////////  2. Read the file as a data Url
  var reader = new FileReader();
  // file read
  reader.onload = function (e) {
    // create img to store data url
    ////// 3 - 1 Create image object for canvas to use
    var img = new Image();
    img.onload = function () {
      /////////// 3-2 send image object to function for manipulation
      complete(resizeInCanvas(img));
    };
    img.src = e.target.result;
  };
  // read file
  reader.readAsDataURL(file);
}
function resizeInCanvas(img) {
  /////////  3-3 manipulate image
  var perferedWidth = 600;
  var ratio = perferedWidth / img.width;
  var canvas = $("<canvas>")[0];
  canvas.width = img.width * ratio;
  canvas.height = img.height * ratio;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  //////////4. export as dataUrl
  return canvas.toDataURL();
}
export const ClearBase64Url = (url) => {
  const b = ";base64,";
  return url.substring(url.indexOf(b) + b.length, url.length);
};

export function maxLen(max) {
  return `لايمكن ان يكون عدد احرف الحقل اكثر من ${max} حرف`;
}

export const orderStatus = [
  "تم إرسال الطلب",
  "الطلب تحت الدراسة",
  "مطلوب بيانات إظافية",
  "تمت الموافقة",
  "تم إيداع المبلغ",
  "إنتظار التسديد",
  "تم التسديد",
  "مكتمل",
  "ملغي",
  "مرفوض",
];
/**
 * Get the arabic transalation of a status
 * @param {number} status
 */
export function OrderTypeTransaltion(status) {
  return orderStatus[status];
}
/**
 * Get bootstrap theme
 * @param {number} status
 */
export function StatusTheme(status) {
  if ([0, 1, 2].includes(status)) return "warning";
  else if ([3].includes(status)) return "info";
  else if ([4, 5, 6, 7].includes(status)) return "success";
  else if ([8, 9].includes(status)) return "danger";
  return "";
}

export function GroupArrayByYearMonth(array) {
  const groupsByYear = {};

  array.forEach((item) => {
    let date = item.Date;

    if (typeof date === "string") {
      date = new Date(date);
    }

    const year = date.getFullYear();

    if (!groupsByYear[year]) {
      groupsByYear[year] = {};
    }

    const month = date.getMonth();

    if (!groupsByYear[year][month]) {
      groupsByYear[year][month] = [];
    }

    groupsByYear[year][month].push(item);
  });

  const result = Object.keys(groupsByYear).map((year) => {
    const months = groupsByYear[year];
    const yearResult = {
      year: parseInt(year),
      months: [],
    };

    Object.keys(months).forEach((month) => {
      const monthItems = months[month];
      const date = new Date(parseInt(year), parseInt(month));

      yearResult.months.push({
        month: parseInt(month),
        date,
        items: monthItems,
      });
    });

    return yearResult;
  });

  return result;
}

// function name(params) {
//   "len",
//     "can be empty or with string at least 2 characters and not more than 10",
//     (val) => {
//       if (val === undefined) {
//         return true;
//       }
//       return val.length === 0 || (val.length >= 2 && val.length <= 10);
//     }
// }

export function DateTimeArabic(date) {
  try {
    return new Intl.DateTimeFormat("ar-SA", {
      dateStyle: "full",
      timeStyle: "short",
      calendar: "gregory",
      numberingSystem: "arab",
    }).format(new Date(date));
  } catch (error) {
    return new Date(date).toLocaleString();
  }
}

export function iziAsk({
  title = "سؤال تأكيد",
  message = "هل انت متأكد من انك تريد الحذف؟",
  okText = "نعم",
  noText = "إلغاء",
  callback,
}) {
  iziToast.question({
    timeout: 10000,
    close: false,
    overlay: true,
    displayMode: "once",
    id: "question",
    zindex: 999,
    layout: 2,
    rtl: true,
    title,
    message,
    position: "center",
    buttons: [
      [
        `<button><b>${okText}</b></button>`,
        function (instance, toast) {
          instance.hide({ transitionOut: "fadeOut" }, toast, true);
        },
        true,
      ],
      [
        `<button>${noText}</button>`,
        function (instance, toast) {
          instance.hide({ transitionOut: "fadeOut" }, toast);
        },
      ],
    ],
    onClosing: function (instance, toast, isYes) {
      if (callback) callback(isYes === true);
    },
  });
}

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];
const formatter = new Intl.RelativeTimeFormat("ar-SA", {
  numeric: "auto",
});
/**
 *
 * @param {Date} date
 */
export function FormatTimeAge(date) {
  let duration = (new Date(date) - new Date()) / 1000;
  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
}
