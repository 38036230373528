import { useFormikContext } from 'formik'
import React, { useMemo } from 'react'

export default function FormInputWithIcon({ propName, required, className, containerClassName, title, prepend, append, ...etc }) {
    const { isSubmitting, values, handleChange, errors } = useFormikContext()
    const invalid = useMemo(() => errors && errors[propName], [errors])
    return (
        <div className={`form-group  ${containerClassName ?? ''}`}>
            <label>{title}{required ? <span className='required mx-2 text-danger'>*</span> : null}</label>
            <div className={`input-group ltr ${invalid ? " invalid" : ""}`}>
                {prepend && <div className="input-group-prepend">
                    <div className="input-group-text">
                        {prepend}
                    </div>
                </div>}
                <input type="text" disabled={isSubmitting} value={values[propName]} onChange={handleChange(propName)} className={"form-control phone-number rtl " + (className ?? "")} {...etc} />
                {append && <div className=" input-group-append">
                    <div className="input-group-text">
                        {append}
                    </div>
                </div>}
            </div>
            {invalid && <div className="invalid-feedback">
                {errors[propName]}
            </div>}
        </div>
    )
}
