import React, { useMemo } from 'react'
import { Field, useFormikContext } from "formik"
import InputMask from 'react-input-mask';
import { getBankByIBAN } from '../utilities/validateIBAN';
export default function IBANInput({ containerClassName = "", required }) {
    const { values, isSubmitting, errors } = useFormikContext();
    const invalid = useMemo(() => errors && errors["IBANNumber"], [errors])
    const subText = useMemo(() => {
        if (!errors["IBANNumber"] && values.IBANNumber) {
            const bk = getBankByIBAN(values.IBANNumber);
            if (bk) {
                return bk.nameAr;
            }
        }
        return null;
    }, [errors])
    return (
        <div className={`form-group ${containerClassName}`}><label className={required ? "required" : ""}>رقم الحساب IBAN</label>
            <Field name="IBANNumber">
                {({ field }) => (
                    <InputMask
                        {...field}
                        mask="**** **** **** **** **** ****"
                        maskChar=" "
                        className={`form-control ltr${invalid ? " invalid" : ""}`}
                        disabled={isSubmitting}
                    />
                )}
            </Field>
            {subText && <span className='d-block mt-1 small text-primary'><i className="fa-solid fa-circle-info mx-2"></i>{subText}</span>}
            {invalid && <div className="invalid-feedback">
                {errors["IBANNumber"]}
            </div>}
        </div>

    )
}
