import React from 'react'

export default function DHead({ title, children }) {
    return (
        <>
            <div className="section-header rtl">
                <h1>{title}</h1>
            </div>
            <div className="section-body">
                {children}
            </div>
        </>
    )
}
