import React from 'react'
import CopyRightsText from '../components/CopyRightsText'
import FormInput from '../components/FormInput'
import FormInputWithIcon from '../components/FormInputWithIcon'

import * as Yup from "yup"
import { Formik } from "formik"
import { maxLen } from '../utilities/functions'
import $ from "jquery"
import iziToast from 'izitoast'
import { Link, useNavigate } from 'react-router-dom'
import { ibanNumber, requiredIdNumber, requiredPhoneNumber } from '../utilities/validations'
import Logo from '../components/Logo'

//Name200,IdentityNumber100,Occupation200,WorkPlace200,BankName200,IBANNumber100,City70,Address150
const validationSchema = Yup.object({
    Name: Yup.string().required("اكتب اسمك!"),
    IdentityNumber: requiredIdNumber,
    Occupation: Yup.string(),
    WorkPlace: Yup.string(),
    BankName: Yup.string(),
    IBANNumber: ibanNumber,
    PhoneNumber: requiredPhoneNumber,
    Address: Yup.string(),
    City: Yup.string(),
    Email: Yup.string().email("البريد الإلكتروني غير صالح").required("بريدك الإلكتروني لايمكن ان يكون فارغا"),
    Password: Yup.string().required("الرجاء اختيار رمز سري").min(8, "يجب ان لايكون الرمز السري اقل من 8 احرف او ارقام"),
    ConfirmPassword: Yup.string().required("لايمكن ان يكون الحقل فارغاً").oneOf([Yup.ref('Password')], "لايتطابق مع رمز المرور!"),
});

export default function RegisterPage() {
    const navigate = useNavigate();
    return (
        <section className="section">
            <div className="container mt-5">
                <div className="row text-right">
                    <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2">
                        <div className="login-brand">
                            <Logo width={180} />
                        </div>

                        <div className="card card-primary">
                            <div className="card-header justify-content-center">
                                <h4>تسجيل مستخدم جديد</h4>
                            </div>

                            <div className="card-body rtl">
                                <Formik
                                    // validateOnBlur={false}
                                    // validateOnChange={false}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        $.ajax({
                                            url: "/api/manage/create",
                                            method: "POST",
                                            data: values
                                        }).then(() => {
                                            iziToast.success({
                                                message: "لقد تم انشاء الحساب بنجاح، لقد قمنا بإرسال رسالة تأكيد لبريدك الإلكتروني. الرجاء تفحص بريدك اللإلكتروني واتباع التعليمات لتفعيل حسابك.",
                                                rtl: true,
                                                position: 'center',
                                                onClosing: () => {
                                                    navigate("/")
                                                }
                                            })
                                        }).catch(error => {
                                            if (error.status == 400 && error.responseJSON) {
                                                const message = error.responseJSON.includes("userexists") ? "هذا المستخدم موجود مسبقاً" : error.responseJSON.includes("userdeleted") ? "لقد تم حذف هذا الحساب بطلب من المستخدم، الرجاء الاتصال بالادارة لإستعادة هذا الحساب" : error.responseJSON.includes("sameidentity") ? "هنالك حساب مسبق يستخدم رقم الهوية التي قمت بإدخالها، الرجاء التأكد من صحة رمز الهوية والمحاولة لاحقا" : "حصل خطا ولايمكن إتمام هذه العملية في الوقت الحالي. الرجاء المحاولة لاحقا";
                                                iziToast.error({
                                                    message,
                                                    rtl: true
                                                })
                                            }
                                            setSubmitting(false)
                                        })
                                    }}
                                    initialValues={{
                                        Name: "",
                                        Email: "",
                                        Password: "",
                                        ConfirmPassword: "",
                                        PhoneNumber: ""
                                    }}
                                >
                                    {({ handleSubmit, isSubmitting }) => (
                                        <form method="POST" noValidate onSubmit={handleSubmit}>
                                            <div className="row">
                                                <FormInput title="الأسم" required propName="Name" containerClassName="col-md-6" maxLength={200} />
                                                <FormInput title="البريد الإلكتروني" required propName="Email" containerClassName="col-md-6" maxLength={256} />
                                                <FormInput title="رمز المرور" type="password" required propName="Password" containerClassName="col-md-6" />
                                                <FormInput title="تأكيد رمز المرور" type="password" required propName="ConfirmPassword" containerClassName="col-md-6" />
                                                <FormInputWithIcon title="رقم الهاتف" maxLength={9} required className="no-spinner text-left" containerClassName="col-md-6" prepend="+966" propName="PhoneNumber" />
                                                <FormInput title="رقم الهوية | الإقامة" required propName="IdentityNumber" containerClassName="col-md-6" maxLength={10} />
                                                <FormInput title="العنوان" propName="Address" maxLength={150} containerClassName="col-md-6" />
                                                <FormInput title="المدينة" propName="City" containerClassName="col-md-6" maxLength={70} />

                                                <FormInput title="المهنة" propName="Occupation" containerClassName="col-md-6" maxLength={200} />
                                                <FormInput title="جهة العمل" propName="WorkPlace" containerClassName="col-md-6" maxLength={200} />
                                                <FormInput title="إسم البنك" propName="BankName" containerClassName="col-md-6" maxLength={200} />
                                                <FormInput title="رقم الحساب IBAN" propName="IBANNumber" containerClassName="col-md-6" maxLength={100} />
                                            </div>
                                            <div className="form-group">
                                                <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-lg btn-block">
                                                    تسجيل
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                                <div className="text-center">

                                    <Link to="/">قم بتسجيل الدخول</Link>
                                </div>
                            </div>
                        </div>
                        <div className="simple-footer">
                            <CopyRightsText />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
