import React, { useMemo, useState } from 'react'
import CopyRightsText from './../components/CopyRightsText';
import FormInput from '../components/FormInput';
import * as Yup from "yup"
import { Formik } from "formik"
import { Link, useNavigate } from 'react-router-dom';
import BModel from '../components/BModel';
import $ from "jquery"
import iziToast from 'izitoast';
import { FormatTimeAge } from '../utilities/functions';
import Logo from "../components/Logo"

const resetValidationSchema = Yup.object({
  Password: Yup.string()
    .required("الرجاء اختيار رمز سري")
    .min(8, "يجب ان لايكون الرمز السري اقل من 8 احرف او ارقام"),
  PasswordConfirmation: Yup.string()
    .required("لايمكن ان يكون الحقل فارغاً")
    .oneOf([Yup.ref("Password")], "لايتطابق مع رمز المرور!"),
});
const emailValidation = Yup.object({
  email: Yup.string().email("البريد الإلكتروني غير صالح").required("بريدك الإلكتروني لايمكن ان يكون فارغا")
})
const codeValidation = Yup.object({
  code: Yup.string().required("لايمكن ان يكون الكود فارغاً"),
})
export default function ResetPasswordPage() {
  //const [pageState, setPageState] = useState(0);
  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);
  //const [code, setCode] = useState(null);
  const [showDlg, setShowDlg] = useState(false);
  const navigate = useNavigate();

  const emailPart = useMemo(() => {
    if (email) return null;
    return (<div className="card-body">
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{ email: "" }}
        validationSchema={emailValidation}
        onSubmit={(values, { setSubmitting }) => {
          $.ajax(`/api/manage/resetpassword?email=${values.email}`).then(({ email, expires }) => {
            setEmail(email)
          }).catch(() => {
            iziToast.info({
              timeout: 10000,
              close: true,
              overlay: true,
              displayMode: 'once',
              // id: 'question',
              zindex: 999,
              layout: 1,
              rtl: true,
              // title: 'سؤال تأكيد',
              message: `<div className="my-3">تم إرسال بريد الكتروني لإعادة تعيين رمز المرور الخاص بك. الرجاء قراءة البريد المرسل واتباع التعليمات لتغيير رمز المرور</div>`,
              position: 'center',
              backgroundColor: "#34395e",
              titleColor: "#fff",
              messageColor: "#f4f4f4",
              iconColor: "#ffff",
              color: "#fff",
              onClosing: function () {
                navigate("/")
              }
            });
          }).always(() => {
            setSubmitting(false)
          })
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form method="POST" noValidate className='rtl' onSubmit={handleSubmit}>
            <p className="text-muted text-center">الرجاء ادخال بريدك الإلكتروني</p>

            <FormInput title="البريد الإلكتروني" propName="email" type="email" />
            <div className="form-group">
              <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-lg btn-block">
                إعادة تعيين كلمة المرور
              </button>
            </div>
          </form>
        )}
      </Formik>
      <div className="text-center">
        <Link to="/">قم بتسجيل الدخول</Link>
      </div>
    </div>);
  }, [email])

  const checkCodePart = useMemo(() => {
    if (email && !token) {
      return (
        <div className="card-body">
          <Formik
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={{ code: "" }}
            validationSchema={codeValidation}
            onSubmit={(values, { setSubmitting }) => {
              $.ajax({
                url: `/api/manage/checkcode`,
                method: 'POST',
                data: { email, ...values }
              }).then(({ token }) => {
                setToken(token)
              }).catch(({ status }) => {
                if (status === 410) {
                  iziToast.error({
                    //title: 'تنوية',
                    message: 'لقد إنتهت صلاحية رمز التأكيد الرجاء المحاولة مره اخرى!',
                    onClosed: () => {
                      navigate("/")
                    }
                  });
                } else {
                  iziToast.error({
                    //title: 'تنوية',
                    message: 'رمز التأكيد الذي قمت بإدخاله غير صحيح!',
                  });
                }
                // resent code 
              }).always(() => {
                setSubmitting(false)
              })
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form method="POST" noValidate className='rtl' onSubmit={handleSubmit}>
                <p className="text-muted text-center">لقد قمنا بإرسال رمز التأكيد الى بريدك الإلكتروني، الرجاء تفحص بريدك الإلكتروني وكتابة رمز التأكيد للمواصلة</p>
                <FormInput title="رمز التأكيد" maxLength={5} style={{ textAlign: 'center' }} propName="code" />
                <div className="form-group">
                  <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-lg btn-block">
                    تغيير رمز المرور                             </button>
                </div>
              </form>
            )}
          </Formik>
          <div className="text-center">
            <Link to="/">قم بتسجيل الدخول</Link>
          </div>
        </div>
      )
    }
    return null;
  }, [email, token])
  const newPassPart = useMemo(() => {
    if (email && token) {
      return <div className="card-body">
        <Formik
          validateOnChange={false}
          validateOnBlur={true}
          initialValues={{ PasswordConfirmation: "", Passowrd: "" }}
          validationSchema={resetValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            $.ajax({
              url: `/api/manage/resetpassword`,
              method: 'POST',
              data: { email, token, ...values }
            }).then(() => {
              iziToast.success({
                position: 'center',
                overlay: true,
                backgroundColor: "#34395e",
                titleColor: "#fff",
                messageColor: "#f4f4f4",
                iconColor: "#ffff",
                color: "#fff",
                message: "لقد تم تغيير رمز المرور بنجاح. يمكنك تسجيل الدخول الأن بإستخدام رمز المرور الجديد",
                onClosing: () => {
                  navigate("/")
                }
              })
            }).catch(() => {
              iziToast.error({
                message: "لم نستطع إكمال العملية في الوقت الحالي. الرجاء المحاولة لاحقا",
                onClosing: () => {
                  navigate("/")
                }
              })
            }).always(() => {
              setSubmitting(false)
            })
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form method="POST" noValidate className='rtl' onSubmit={handleSubmit}>
              <p className="text-muted text-center"> الرجاء إختيار رمز مرور جديد</p>
              <FormInput title="رمز الدخول الجديد" propName="Password" type="password" />
              <FormInput title="تأكيد رمز الدخول الجديد" propName="PasswordConfirmation" type="password" />
              <div className="form-group">
                <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-lg btn-block">
                  تغيير رمز المرور                             </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    }
    return null;
  }, [email, token])

  return (
    <section className="section">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="login-brand">
              <Logo width={180} />
            </div>
            <div className="card card-primary">
              <div className="card-header justify-content-center"><h4>إعادة تعيين كلمة المرور</h4></div>
              {emailPart}
              {checkCodePart}
              {newPassPart}
            </div>
            <div className="simple-footer">
              <CopyRightsText />
            </div>
          </div>
        </div>
      </div>
      <BModel show={showDlg} setShow={() => {
        navigate("/")
      }} okText="Hi" cancelText="إغلاق" title="رسالة تأكيد" size="md" center>
        <p>لقد تم إعادة تعيين رمز المرور الخاص بنجاح، يمكنك الان الدخول بأستخدام الرمز الجديد.</p>
      </BModel>
    </section>
  )
}
const min5 = 1000 * 5 * 60;
const ResetCodeTimer = ({ callback, email, expires }) => {
  const [code, setCode] = React.useState("");
  // const [time, setTime] = React.useState(Date.now() + 10000);
  const [expired, setExpired] = React.useState(false);
  const [isChecking, setIsChecking] = React.useState(false);
  const [expireTime, setExpireTime] = useState(expires);
  const expTime = useMemo(() => {
    if (expireTime) return new Date(expireTime);
    return new Date(new Date() + min5);
  }, [expireTime])
  // const [restart, setRestart] = React.useState(0);
  // const renderer = ({ hours, minutes, seconds, completed }) => {
  //   if (completed || expired) {
  //     setExpired(true);
  //     // Render a completed state
  //     return <p className="text-danger text-center">إنتهت وقت صلاحية الكود<br />00:00:00</p>;
  //   } else {
  //     // Render a countdown
  //     return <div className='text-center'><span><>سينتهي الكود خلال</></span><p><span>{hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</span></p></div>;
  //   }
  // };
  // const expTime = useMemo(() => Date.now() + (min5), [restart])
  const handleCheckCode = () => {
    if (expired) {
      setIsChecking(true);
      $.ajax({
        url: `/api/manage/resetpassword?email=${email}`
      }).always(() => {
        setIsChecking(false);
        setExpireTime(new Date(new Date() + min5))
        // setRestart(restart + 1)
        // setExpired(false)
      })
    } else if (code && code.length === 5) {
      setIsChecking(true);
      $.ajax({
        url: "/api/manage/checkcode",
        method: "POST",
        data: {
          email, code
        }
      }).then(({ token }) => {
        callback(token);
      }).catch(() => {
        iziToast.error({
          message: "لم يتطابق الكود، قد تكون انتهت صلاحية الكود . الرجاء التأكد من الرمز والمحاولة مرة اخرى"
        })
      }).always(() => setIsChecking(false));
      callback(code);
    }
  }
  return (
    <div className="card-body">
      <p className="text-muted text-center">لقد تم إرسال رمز إعادة تعيين كلمة المرور الى بريدك الألكتروني، الرجاء كتابة هذا الرقم والضغط على زر تحقق من الرمز</p>
      <div>
        {!expired && <div className="form-group">
          <input value={code} onChange={e => {
            const str = e.target.value ?? "";
            setCode(str.substring(0, 5))
          }} type="number" minLength={5} maxLength={5} className={`form-control text-center no-spinner ${code?.length !== 5 ? " invalid" : ""}`} disabled={isChecking} />
        </div>}
        <p className='text-center text-danger'>ستنتهي صلاحية الكود {FormatTimeAge(expTime)} </p>
        {/* <Countdown
          key={restart}
          date={new Date() - (expires ?? expTime)}
          renderer={renderer}
        /> */}
        <div className="form-group">
          <button onClick={handleCheckCode} disabled={isChecking} type="submit" className={`btn btn-${expired ? "danger" : "primary"} btn-lg btn-block`}>
            {expired ? " !أطلب كود جديد" : "تحقق من الرمز"}
          </button>
        </div>
      </div>
    </div>
  )
}