import React from 'react'

export default function LoadingSpinner({ title = "الرجاء الإنتظار", color = "text-primary", ...etc }) {
    return (
        <div style={{ minHeight: "300px" }} className="h-100 w-100 d-flex flex-column justify-content-center align-items-center" {...etc}>
            <div className={`spinner-border ${color}`}>
            </div>
            <h6 className={`my-5 ${color}`}>{title}</h6>
        </div>
    )
}
