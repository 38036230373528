import { atom } from "recoil";

//atom مكون نشتي نحفظة

// الثلاث التاليات مايشتغلين الا اذا ارسلت لهن ATOM
//useRecoilState  simplar to react useState
//useSetRecoilState used for set only
//useRecoilValue use value only

export const userState = atom({
  key: "user",
  default: null,
});

export const notificationState = atom({
  key: "notification",
  default: null,
});

export const loadingState = atom({
  key: "loading",
  default: false,
});
