import React, { useEffect, useRef, useState } from 'react'
import DHead from '../components/DHead'
import DefaultTable from '../components/DTable'
import * as Yup from "yup"
import FormInput from './../components/FormInput';
import SelectClient from '../components/SelectClient';
import { DateTimeArabic, OrderTypeTransaltion, consts, orderStatus } from '../utilities/functions';
import FormSelect from './../components/FormSelect';
import UserCard from '../components/UserCard';
import $ from "jquery"
import BModel from '../components/BModel';
import JustInput from './../components/JustInput';
import iziToast from 'izitoast';
import RenderAttachments from '../components/RenderAttachments';

// validation schema
const newValidationSchema = Yup.object().shape({
    UserId: Yup.string().required("لايمكن ان يكون اسم العميل فارغا"),
    OrderTypeId: Yup.string().required("يجب تحديد نوع الطلب"),
})
const UserInfo = ({ Name, Email, PhoneNumber }) => {
    return (
        <span className='small'>
            {Name}<br />
            {Email}
            {PhoneNumber && <><br />{PhoneNumber}</>}
        </span>
    )
}

const CalculatePaid = (pays) => {
    if (pays && pays.length > 0) return (pays.reduce((ac, p) => ac += p.Amount, 0)).toFixed(2);
    return "0.00";
}
// table columns
const columns = [
    { Header: "التاريخ | الوقت", accessor: 'Date', isSorted: true, Cell: ({ value }) => DateTimeArabic(value) },
    { Header: "العميل", accessor: 'UserId', Cell: ({ value, row }) => <UserInfo {...row?.original?.US} /> },
    { Header: "نوع الطلب", accessor: 'OrderTypeId', Cell: ({ value, row }) => row?.original?.OT?.Name },
    { Header: "المبلغ", accessor: 'Price' },
    {
        Header: "ماتم تسديدة", accessor: 'CPayments', disableSortBy: true, Cell: ({ value }) => {
            return CalculatePaid(value);
        }
    },
    { Header: "حالة الطلب", accessor: 'Status', Cell: ({ value }) => OrderTypeTransaltion(value) }
]

const AddPayment = ({ values, callback, setShow, ...etc }) => {
    const cont = useRef(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    return (
        <BModel isSubmitting={isSubmitting} okText="حفظ" title="المدفوعات : إضافة" cancelText="إلغاء" size='md' setShow={setShow} handleSubmit={() => {
            const amount = $(cont.current).find('[type="number"]').val();
            let date = $(cont.current).find('[type="date"]').val();
            if (!date) date = new Date().toLocaleDateString();
            if (!amount || amount <= 0) {
                iziToast.error({
                    message: "يجب ان تكون قيمة المبلغ صحيحة واكبر من 0 ريال"
                })
                return;
            }
            setIsSubmitting(true);
            $.ajax({
                url: "/api/order/addpayment",
                method: "POST",
                data: { amount, orderId: values.Id, date }
            }).then(resp => {
                callback(resp)
                iziToast.success({
                    message: "تمت الإضافة بنجاح"
                })
                setShow(false);
            }).always(() => {
                setIsSubmitting(false);
            })
        }}  {...etc}>
            <div ref={cont}>
                <div className="row">
                    <div className="col-6">
                        <p>قيمة القرض :{values.Price}</p>
                    </div>
                    <div className="col-6">
                        <p>المبلغ المدفوع حتى الان :{CalculatePaid(values.CPayments)}</p>
                    </div>
                </div>
                <JustInput type='number' required title="المبلغ" min="1" />
                <JustInput type='date' title="بتاريخ" subText="ستكون القيمة الإفتراضية هي الان في حال كان الحقل فارغاً" />
            </div>
        </BModel>
    )
}

const FormContent = ({ values, setFieldValue, errors }) => {
    const [clients, setClients] = useState([]);
    const [showAddPay, setShowAddPay] = useState(false);
    useEffect(() => {
        if (values.Id !== 0 && (values.AcceptedBy || values.RejectedBy || values.ClosedBy)) {
            $.ajax('/api/order/u?id=' + values.Id).then(data => {
                if (data && data.length > 0) {
                    data.forEach(u => {
                        if (values.AcceptedBy == u.Id) values.AcceptedByName = u.Name;
                        if (values.RejectedBy == u.Id) values.RejectedByName = u.Name;
                        if (values.ClosedBy == u.Id) values.ClosedByName = u.Name;
                    })
                    setFieldValue("X", "");
                }
            }).catch(console.log)
        }
    }, [])
    if (values.Id === 0)
        return (
            <>
                <div className="col-12 col-md-6">
                    <SelectClient title="العميل" required max={1} type={5} error={errors['UserId']} clients={clients} setClients={data => {
                        setClients(data);
                        setFieldValue("UserId", data && data.length > 0 ? data[0].Id : '')
                    }} />
                </div>
                <FormSelect title="نوع الطلب" propName="OrderTypeId" required containerClassName="col-12 col-md-6">
                    <option></option>
                    {consts.orderTypes != null && consts.orderTypes.map(c => <option key={c.Id} value={c.Id}>{c.Name}</option>)}
                </FormSelect>
                <FormInput title="كود المندوب" propName="RepresentitveCode" containerClassName="col-12 col-md-6" maxLength={8} />
            </>
        )

    return (
        <>
            <div className="col-md-6">
                <div className="list-group">
                    <div className="list-group-item active text-center">معلومات العميل</div>
                    <div className="list-group-item p-0">
                        <UserCard {...values.US} />
                    </div>
                </div>
            </div>
            <div className="col-md-6 small">
                <FormSelect title="حالة الطلب" propName="Status">
                    {orderStatus.map((c, index) => <option value={index} key={index}>{c}</option>)}
                </FormSelect>
                <FormInput title="كود المندوب" propName="RepresentitveCode" maxLength={8} />
                {values.AcceptedBy && <div className='list-group mb-2'>
                    <div className="list-group-item active">
                        تم قبول الطلب
                    </div>
                    <div className="list-group-item">
                        <div className="row">
                            <div className="col-6 text-center">{DateTimeArabic(values.AcceptedDate)}</div>
                            <div className="col-6 text-center">
                                {values.AcceptedByName ?? <div className="spinner-border small">
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>}
                {values.RejectedBy && <div className='list-group mb-2'>
                    <div className="list-group-item list-group-item-danger">
                        تم رفض الطلب
                    </div>
                    <div className="list-group-item">
                        <div className="row">
                            <div className="col-6 text-center">{DateTimeArabic(values.AcceptedDate)}</div>
                            <div className="col-6 text-center">
                                {values.RejectedByName ?? <div className="spinner-border small">
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>}
                {values.ClosedBy && <div className='list-group mb-2'>
                    <div className="list-group-item list-group-item-success">
                        تم تحديد الطلب كـ مكتمل
                    </div>
                    <div className="list-group-item">
                        <div className="row">
                            <div className="col-6 text-center">{DateTimeArabic(values.AcceptedDate)}</div>
                            <div className="col-6 text-center">
                                {values.ClosedByName ?? <div className="spinner-border small">
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>}
                <RenderPayments CPayments={values.CPayments} onAdd={() => setShowAddPay(true)} onDelete={(Id) => {
                    $.ajax('/api/order/removepayment?id=' + Id).then((resp) => {
                        setFieldValue("CPayments", values.CPayments.filter(z => z.Id !== Id))
                        iziToast.success({
                            message: "تم الحذف بنجاح"
                        })
                    })
                }} />
                <RenderAttachments order={values} canManage={false} />
                {showAddPay ?
                    <AddPayment show={showAddPay} values={values} setShow={() => setShowAddPay(false)} callback={r => {
                        setFieldValue('CPayments', [...values.CPayments, r])
                    }} />
                    : null}
            </div>
        </>
    )
}

export const RenderPayments = ({ onAdd, onDelete, CPayments, hideTotal = false, hideUser = false, theme = "primary", ...etc }) => {
    return (
        <div {...etc}>
            <div className="list-group mb-2">
                <div className={`list-group-item list-group-item-${theme} d-flex justify-content-between align-items-center`}>المدفوعات{onAdd && <span className={`btn btn-${theme} btn-sm py-0`} onClick={onAdd}><span className='mx-2'><i className="fa-solid fa-circle-plus ml-2"></i></span>إضافة</span>}</div>
                {CPayments && CPayments.length > 0 ? <>
                    {CPayments.map(c => <div key={c.Id} className="list-group-item d-flex justify-content-between align-items-center">
                        <div className='d-flex flex-column'>
                            <span>المبلغ : <b>{(c.Amount).toFixed(2)}</b></span>
                            <span>بتاريخ : <b>{DateTimeArabic(c.Date)}</b></span>
                            {!hideUser &&
                                <span>الموظف : <b>{c.Name}</b></span>
                            }
                        </div>
                        {onDelete &&
                            <span className='btn btn-sm btn-danger btn-round' onClick={() => {
                                iziToast.question({
                                    timeout: 10000,
                                    close: false,
                                    overlay: true,
                                    displayMode: 'once',
                                    id: 'question',
                                    zindex: 999,
                                    layout: 2,
                                    rtl: true,
                                    title: 'سؤال تأكيد',
                                    message: 'هل انت متأكد من انك تريد الحذف؟',
                                    position: 'center',
                                    buttons: [
                                        ['<button><b>نعم</b></button>', function (instance, toast) {
                                            instance.hide({ transitionOut: 'fadeOut' }, toast, true);

                                        }, true],
                                        ['<button>إلغاء</button>', function (instance, toast) {
                                            instance.hide({ transitionOut: 'fadeOut' }, toast);
                                        }],
                                    ],
                                    onClosing: function (instance, toast, isYes) {
                                        if (isYes === true) {
                                            onDelete(c.Id);
                                        }
                                    }
                                });
                            }}>حذف</span>
                        }

                    </div>)}
                    {!hideTotal && <div className={`list-group-item list-group-item-${theme}`}>إجمالي المدفوعات ({CalculatePaid(CPayments)})</div>}
                </> : <div className='list-group-item text-center text-muted'>لاتوجد اي مدفوعات</div>}
            </div>
        </div>
    )
}
// export const RenderAttachments = ({ order, theme = "primary", onAdded, onDeleted }) => {
//     return (
//         <div className="list-group">
//             <div className={`list-group-item list-group-item-${theme} d-flex justify-content-between align-items-center`}>المستندات</div>
//             {order.CAttachments && order.CAttachments.length > 0 ? <></> : <div className='list-group-item text-center text-muted'>لايوجد اي مستند</div>}
//         </div>
//     )
// }

export default function Orders() {
    return (
        <DHead title="الطلبات">
            <DefaultTable
                columns={columns}
                url="/api/order"
                schema={(data, isNew) => {
                    return newValidationSchema;
                }}
                FormContent={FormContent}
                defaultValues={{ Id: 0 }}
                editorProps={{
                    size: "xl",
                    center: true,
                    bodyProps: {
                        style: {
                            minHeight: "400px"
                        }
                    }
                }}
                prerequisite={(values) => !(values.Id === 0 && (consts.orderTypes === null || consts.orderTypes === undefined || consts.orderTypes.length === 0))}
            />
        </DHead>
    )
}
