import React, { useState, useEffect, useMemo } from 'react'
import DHead from '../components/DHead'
import UserCard from '../components/UserCard'
import { useRecoilValue } from 'recoil'
import { userState } from '../utilities/app-store'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from '../components/LoadingSpinner'
import $ from "jquery"
import { FormatTimeAge, consts } from '../utilities/functions'
import { orderStatus } from '../utilities/functions'
import { RenderPayments } from "./Orders"
import RenderAttachments from '../components/RenderAttachments'
import ClientAddOrder from '../components/ClientAddOrder'

export default function ClientOrders() {
    const user = useRecoilValue(userState)
    const navigator = useNavigate();
    const [addOrder, setAddOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [orders, setOrders] = useState(null);
    const loadData = () => {
        $.ajax("/api/order/client").then(data => {
            setOrders(data);
        })
    }
    useEffect(() => {
        if (consts.userData) {
            setUserData(consts.userData)
            setLoading(false);
        } else {
            $.ajax("/api/user/profile").then(data => {
                consts.userData = data;
                setUserData(data)
                setLoading(false);
            })
        }
        loadData();
    }, [])

    if (loading || !userData) return <LoadingSpinner />;
    return (
        <>
            <DHead title="إدارة الطلبات">
                <div className="row rtl">
                    <div className="col-12 mb-4">
                        <div className="hero bg-primary text-white rtl" >
                            <div className="hero-inner">
                                <h2>مرحبا بك {user?.Name} <span className='mx-2'>😘</span> </h2>
                                <p className="lead">نسعد بخدمتكم دائما, يمكنك إدارة ومتابعة طلباتك في هذا القسم</p>
                                <div className="mt-4">
                                    <button type='button' onClick={() => setAddOrder(1)} className="btn btn-outline-white btn-lg btn-icon icon-left">إنشئ طلب جديد</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <UserCard {...userData}>
                            <button className="btn btn-primary mt-4" type="button" onClick={() => navigator("/profile")}>قراءة وتعديل المعلومات</button>
                        </UserCard>
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                            {orders === null ? <LoadingSpinner title="جاري تحميل البيانات" /> : <>
                                {orders.map(order =>
                                    <div key={order.Id} className="col-12">
                                        <OrderItem order={order} orderUpdated={values => {
                                            const nOrders = orders.map(c => {
                                                if (c.Id === values.Id) return values;
                                                return c;
                                            })
                                            setOrders(nOrders);
                                        }} />
                                    </div>
                                )}
                            </>}
                        </div>
                    </div>
                </div>
            </DHead>
            <ClientAddOrder show={addOrder} setShow={setAddOrder} callback={added => {
                if (added) loadData();
            }} />
        </>
    )
}

const OrderItem = ({ order, orderUpdated }) => {
    const theme = useMemo(() => {
        if (order.Status > 7) return "danger";
        if ([7, 8].includes(order.Status)) return "success";
        // if (order.IsClosed) return "dark";
        return "primary"
    }, [])
    return (
        <div className={`card card-${theme}`}>
            <div className="card-header hover-effect" onClick={(e) => {
                $(e.currentTarget).next('.card-body').slideToggle('fast');
            }}>
                <div className={`d-flex justify-content-between w-100 text-${theme}`}>
                    <h6 className='fs-13'>#{order.Id} قيمة القرض {order.Price} ريال</h6>
                    <h6 className='fs-13'>الحالة : {orderStatus[order.Status]}</h6>
                </div>
            </div>
            <div className="card-body" style={{ display: "none" }}>
                <div className="row">
                    <div className="col-md-6">
                        <RenderPayments CPayments={order.CPayments} hideUser theme={theme} className="small" />
                        <RenderAttachments order={order} theme={theme} className="small" onUpdated={data => {
                            if (orderUpdated) orderUpdated(data);
                        }} />
                    </div>
                    <div className="col-md-6">
                        <div className="activities ltr">
                            <ActityItem icon={<i className="fa-solid fa-play"></i>} date={order.Date} text="تم إنشاء وتقديم الطلب" />
                            {order.AcceptedDate && order.Status >= 3 && <ActityItem icon={<i className="fa-solid fa-check"></i>} date={order.AcceptedDate} text="تم قبول الطلب" />}
                            {order.ClosedDate && order.Status === 7 && <ActityItem icon={<i className="fa-solid fa-check-double"></i>} date={order.ClosedDate} text="تم إغلاق الطلب" />}
                            {order.Status !== 7 && <ActityItem icon={<i className="fa-solid fa-spinner"></i>} date={[8, 9].includes(order.Status) && order.RejectedDate ? order.RejectedDate : null} text={orderStatus[order.Status]} theme={[8, 9].includes(order.Status) ? 'danger' : 'warning'} />}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ActityItem = ({ icon, date, text, theme = "primary" }) => {
    return (
        <div className="activity">
            <div className={`activity-icon bg-${theme} text-white shadow-${theme} d-flex justify-content-center align-items-center`}>
                {icon}
            </div>
            <div className="activity-detail">
                {date && <div className="mb-2">
                    <span className="bullet"></span>
                    <span className={`text-job text-${theme}`}>{FormatTimeAge(date)}</span>
                </div>}
                <p className={`text-${theme}`}>{text}</p>
            </div>
        </div>
    )
}