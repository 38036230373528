import React, { useEffect, useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./components/MainLayout";
// import "bootstrap/dist/css/bootstrap.min.css"
// import "./assets/css/style.css";
// import "./assets/css/components.css";
// import "./assets/css/rtl.css";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

import { useRecoilValue } from "recoil";
import { userState, loadingState } from "./utilities/app-store";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import OrderTypes from "./pages/OrderTypes";
import Services from "./pages/Services";
import Users from "./pages/Users";
import Representitive from "./pages/Representitive";
import Items from "./pages/Items";
import $ from "jquery";
import Profile from "./pages/Profile";
import LoadingSpinner from "./components/LoadingSpinner";
import Settings from "./pages/Settings";
import ClientOrders from "./pages/ClientOrders";
import { consts } from "./utilities/functions";
import ConfirmEmail from "./pages/ConfirmEmail";
import Terms from "./pages/Terms";

export default function App() {
  const user = useRecoilValue(userState);
  // user settings
  useEffect(() => {
    if (!user) {
      delete consts.userData;
      consts.user = null;
    }
  }, [user]);
  if (!user) {
    return (
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
        <Route path="/account/confirmemail" element={<ConfirmEmail />} />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    );
  }
  return <Jen user={user} />;
}

const Jen = ({ user }) => {
  const [loaded, setLoaded] = useState(false);
  const showSpinner = useRecoilValue(loadingState);
  useEffect(() => {
    // setup ajax calls
    $.ajaxSetup({
      beforeSend: function (xhr) {
        if (user) {
          // Authorization
          xhr.setRequestHeader("Authorization", `Bearer ${user.token}`);
          // contentType: "application/json; charset=utf-8",
          // xhr.setRequestHeader('x-gs-id', gs.med);
        }
      },
      complete: function () {
        // Handle the complete event
        // progress.end();
      },
      //headers: { 'x-gs-header': 'adel' },
      // error: (er) => {
      //     // if (er.status === 307) {
      //     //     window.location.href = "/Account/SelectedMed";
      //     // }
      // }
    });
    setLoaded(user !== null);
  }, []);
  const isClient = useMemo(() => user.roles?.includes("Client"), [user]);
  const isAdmin = useMemo(
    () => user.roles?.includes("Admin") || user.roles?.includes("Developer"),
    [user]
  );
  if (!loaded) return <LoadingSpinner />;
  return (
    <>
      <MainLayout>
        <Routes>
          {/* <Route path="/" element={<Dashboard />} /> */}

          {!isClient ? (
            <>
              <Route path="/ordertypes" element={<OrderTypes />} />
              <Route path="/services" element={<Services />} />
              <Route path="/items" element={<Items />} />
              <Route path="/users" element={<Users />} />
              <Route path="/representitive" element={<Representitive />} />
              <Route path="/orders" element={<Orders />} />
            </>
          ) : (
            <Route path="/orders" element={<ClientOrders />} />
          )}
          {isAdmin && <Route path="/settings" element={<Settings />} />}
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </MainLayout>
      {showSpinner && (
        <LoadingSpinner
          color="text-white"
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0,0,0,.6)",
            zIndex: 1000,
          }}
        />
      )}
      {/* <Routes>
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
      </Routes> */}
    </>
  );
};

// export default class App extends Component {

//   render() {
//     return <MainLayout />
//     return <ResetPasswordPage />
//     return <ForgetPasswordPage />
//     return <RegisterPage />
//     return <LoginPage />
//   }
// }
