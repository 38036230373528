import React, { useEffect, useRef } from 'react'
import CopyRightsText from './CopyRightsText'
import $ from "jquery"
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { userState } from '../utilities/app-store'
import Notifications from './Notifications'
import MadeWithLove from './MadeWithLove'
import Logo from "../components/Logo"
import DHead from './DHead'
// import "../assets/js/nicescroll"
// import "../assets/js/globale"



function MainLayout2({ children }) {
    const [user, setUser] = useRecoilState(userState);

    const handleSideBarToggle = (event) => {
        event.stopPropagation();
        $(document.body).removeClass("layout-2")
        //"layout-2" for big screens
        if ($(document.body).hasClass("sidebar-gone")) {
            $(document.body).addClass("sidebar-show").removeClass("sidebar-gone")
        } else {
            $(document.body).addClass("sidebar-gone").removeClass("sidebar-show")
        }
    }


    useEffect(() => {
        const mainSidebar = $('.main-sidebar');
        const body = $(document.body);
        //sidebar-show
        // $(document).on('click touch', (event) => {
        //     const isOpen = body.hasClass("sidebar-mini");
        //     const width = $(document).width();
        //     if (width <= 1024 && (!isOpen || mainSidebar.width() > 65)) {
        //         handleSideBarToggle(event);
        //     }
        // })
        // will be used when screen width change or when doc content loaded
        function toggleSideHelper(event) {
            console.log("Change")
            if (window.innerWidth <= 1024 && body.hasClass("layout-2")) {
                $(document.body).removeClass('layout-2').addClass("sidebar-show").removeClass("sidebar-gone")
                // handleSideBarToggle(event);
            } else if (window.innerWidth > 1024 && !body.hasClass("layout-2")) {
                $(document.body).addClass("layout-2").removeClass("sidebar-gone sidebar-show")
            }
        }
        window.addEventListener("resize", toggleSideHelper)
        document.addEventListener("DOMContentLoaded", toggleSideHelper)
        // $(document).on("")
    }, [])

    return (
        <div className="main-wrapper main-wrapper-1">
            <div className="navbar-bg"></div>
            <nav className="navbar navbar-expand-lg main-navbar">
                <form className="form-inline mr-auto">
                    <ul className="navbar-nav mr-3">
                        <li><a onClick={handleSideBarToggle} className="nav-link nav-link-lg"><i className="fas fa-bars"></i></a></li>
                        <li><a data-toggle="search" className="nav-link nav-link-lg d-sm-none"><i className="fas fa-search"></i></a></li>
                    </ul>
                    {/* <div className="search-element">
                        <input className="form-control" type="search" placeholder="Search" aria-label="Search" data-width="250" style={{ width: "250px" }} />
                        <button className="btn" type="submit"><i className="fas fa-search"></i></button>
                        <div className="search-backdrop"></div>
                        <div className="search-result">
                            <div className="search-header">
                                Histories
                            </div>
                            <div className="search-item">
                                <a href="#">How to hack NASA using CSS</a>
                                <a href="#" className="search-close"><i className="fas fa-times"></i></a>
                            </div>
                            <div className="search-item">
                                <a href="#">Kodinger.com</a>
                                <a href="#" className="search-close"><i className="fas fa-times"></i></a>
                            </div>
                            <div className="search-item">
                                <a href="#">#Stisla</a>
                                <a href="#" className="search-close"><i className="fas fa-times"></i></a>
                            </div>
                            <div className="search-header">
                                Result
                            </div>
                            <div className="search-item">
                                <a href="#">
                                    <img className="mr-3 rounded" width="30" src="assets/img/products/product-3-50.png" alt="product" />
                                    oPhone S9 Limited Edition
                                </a>
                            </div>
                            <div className="search-item">
                                <a href="#">
                                    <img className="mr-3 rounded" width="30" src="assets/img/products/product-2-50.png" alt="product" />
                                    Drone X2 New Gen-7
                                </a>
                            </div>
                            <div className="search-item">
                                <a href="#">
                                    <img className="mr-3 rounded" width="30" src="assets/img/products/product-1-50.png" alt="product" />
                                    Headphone Blitz
                                </a>
                            </div>
                            <div className="search-header">
                                Projects
                            </div>
                            <div className="search-item">
                                <a href="#">
                                    <div className="search-icon bg-danger text-white mr-3">
                                        <i className="fas fa-code"></i>
                                    </div>
                                    Stisla Admin Template
                                </a>
                            </div>
                            <div className="search-item">
                                <a href="#">
                                    <div className="search-icon bg-primary text-white mr-3">
                                        <i className="fas fa-laptop"></i>
                                    </div>
                                    Create a new Homepage Design
                                </a>
                            </div>
                        </div>
                    </div> */}
                </form>
                <ul className="navbar-nav navbar-right">
                    <Notifications />
                </ul>
            </nav>
            <div className="main-sidebar sidebar-style-2" style={{ overflow: "hidden", outline: "none" }}>
                <aside id="sidebar-wrapper" style={{ paddingTop: "1rem" }}>
                    <div className="sidebar-brand position-relative">
                        <Link to="/">
                            <img src="/content/logo.svg" width="140" />
                        </Link>
                    </div>
                    <div className="sidebar-brand sidebar-brand-sm">
                        <Link to="/">
                            <img src="/content/logo-s.svg" width="30" />
                        </Link>
                    </div>
                    <ul className="sidebar-menu mt-5">
                        <li><Link className="nav-link" to="/"><i className="fas fa-pencil-ruler"></i> <span>الرئيسية</span></Link></li>
                        <li><Link className="nav-link" to="/orders"><i className="fas fa-server"></i> <span>الطلبات</span></Link></li>
                        {user && user.roles[0] !== "Client" ?
                            <DropDownMenu
                                faclassName="fa-cogs"
                                title="الإعدادات"
                            >
                                <MenuItem to="items">الأصناف</MenuItem>
                                <MenuItem to="ordertypes">انواع الطلبات</MenuItem>
                                {user.roles[0] === "Admin" || user.roles[0] === "Developer"}
                                <MenuItem to="users">المستخدمين</MenuItem>
                                <MenuItem to="representitive">المندوبين</MenuItem>
                            </DropDownMenu> : null
                        }
                    </ul>
                </aside>
            </div>

            {/* <!-- Main Content --> */}
            <div className="main-content" style={{ minHeight: "777px" }}>
                <section className="section">
                    {children}
                </section>
            </div>
            <footer className="main-footer">
                <div className="footer-left">
                    <CopyRightsText showMadeWith={false} />
                </div>
                <div className="footer-right">
                    <MadeWithLove />
                </div>
            </footer>
        </div>
    )
}

export default function MainLayout({ children }) {
    const [user, setUser] = useRecoilState(userState);

    const handleSideBarToggle = (event) => {
        event.stopPropagation();
        if ($(document.body).hasClass("sidebar-show")) {
            $(document.body).removeClass("sidebar-show").addClass("sidebar-gone")
        } else {
            $(document.body).removeClass("sidebar-gone").addClass("sidebar-show")
        }
    }


    useEffect(() => {
        const body = $(document.body);
        // will be used when screen width change or when doc content loaded
        function toggleSideHelper(event) {
            if (window.innerWidth <= 1024 && body.hasClass("layout-2")) {
                body.removeClass('layout-2').addClass("sidebar-show").removeClass("sidebar-gone")
                // handleSideBarToggle(event);
            } else if (window.innerWidth > 1024 && !body.hasClass("layout-2")) {
                body.addClass("layout-2").removeClass("sidebar-gone sidebar-show")
            }
        }
        window.addEventListener("resize", toggleSideHelper)
        document.addEventListener("DOMContentLoaded", toggleSideHelper)
        body.on("click", e => {
            if (e.currentTarget.tagName === "BODY" && body.hasClass("sidebar-show")) {
                body.removeClass("sidebar-show").addClass("sidebar-gone")
            }
        })
        toggleSideHelper()
        // $(document).on("")
    }, [])

    return (<div className='main-wrapper'>
        <div className="navbar-bg"></div>
        {/* Navbar */}
        <nav className="navbar navbar-expand-lg main-navbar">
            <Link to="/" className="navbar-brand sidebar-gone-hide">
                <Logo light width={100} />
            </Link>
            <div className="nav-link sidebar-gone-show bg-primary hover-effect" onClick={handleSideBarToggle}><i className="fas fa-bars fa-3x"></i></div>
            <Notifications />
        </nav>
        {/* Sidebar */}
        <div className="main-sidebar" tabIndex="1" style={{ overflow: 'hidden', outline: 'none' }}>
            <aside id="sidebar-wrapper">
                <div className="sidebar-brand sidebar-gone-show"><Logo width={100} /></div>
                {/* <div className="sidebar-brand position-relative my-5">
                    <Link to="/">
                        <img src="/content/logo.svg" width="140" />
                    </Link>
                </div> */}
                <ul className="sidebar-menu">
                    <li><Link className="nav-link" to="/"><i className="fas fa-pencil-ruler"></i> <span>الرئيسية</span></Link></li>
                    <li><Link className="nav-link" to="/orders"><i className="fas fa-server"></i> <span>الطلبات</span></Link></li>
                    {user && user.roles[0] !== "Client" ?
                        <DropDownMenu
                            faclassName="fa-cogs"
                            title="الإعدادات"
                        >
                            <MenuItem to="items">الأصناف</MenuItem>
                            <MenuItem to="ordertypes">انواع الطلبات</MenuItem>
                            {user.roles[0] === "Admin" || user.roles[0] === "Developer"}
                            <MenuItem to="users">المستخدمين</MenuItem>
                            <MenuItem to="representitive">المندوبين</MenuItem>
                        </DropDownMenu> : null
                    }
                </ul>
            </aside>
        </div>
        {/* Content */}
        <div className='main-content h-75'>
            <section className="section">
                {children}
            </section>
        </div>
        {/* Footer */}
        <footer className="main-footer text-center">
            <CopyRightsText />
            {/* <div className="footer-left">
                <MadeWithLove />
            </div>
            <div className="footer-right">
            </div> */}
        </footer>
    </div>)
}
const MenuHeader = ({ children }) => <li className="menu-header">{children}</li>
const DropDownMenu = ({ title, faclassName, children, }) => {
    const dropMenu = useRef(null)
    const handleClick = (ev) => {
        ev.stopPropagation();
        if (children) {
            $(dropMenu.current).slideToggle("fast");
            // $(".nav-link.has-dropdown").click(function (event) {
            //     event.stopPropagation();
            //     const menu = $(this).next(".dropdown-menu");
            //     menu.slideToggle("fast");
            //     // menu.toggle("slow")
            // })
        }
    }

    return (
        <li className="dropdown">
            <a className="nav-link has-dropdown" onClick={handleClick}>{faclassName && <i className={`fas ${faclassName}`}></i>} <span>{title}</span></a>
            <ul className="dropdown-menu" ref={dropMenu}>{children}</ul>
        </li>
    )
}
const MenuItem = ({ to = "#", children }) => {
    return (
        <li><Link className="nav-link" to={to}>{children}</Link></li>
    )
}