import React, { useState } from 'react'
import $ from "jquery"
import iziToast from 'izitoast';
import BModel from './BModel';
import { Formik } from 'formik';
import FormInput from './FormInput';
import JustInput from './JustInput';

import * as Yup from "yup"
import { iziAsk } from '../utilities/functions';

const uploadValidationSchema = Yup.object().shape({
    Name: Yup.string().label("الوصف").required("لايمكن ان يكون وصف المستند فارغا").max(100, "أقصى عدد للحقل هو 100 حرف او رقم"),
    file: Yup.mixed().label("المستند").nullable().required("يجب اختيار مستند")
        .test("type", "يمكنك رفع صور او مستندات من نوع doc او pdf", (value) => {
            return value && (
                value.type === "image/jpeg" ||
                value.type === "image/bmp" ||
                value.type === "image/png" ||
                value.type === 'application/pdf' ||
                value.type === "application/msword"
            );
        })
        .test("fileSize", "لايمكن رفع ملف اكبر من 3 ميقا بايت", (value) => {
            return value && value.size / (1024 * 1024) < 3;
        })
})


export default function RenderAttachments({ order, theme = "primary", onUpdated, className, canManage = true, ...etc }) {
    const [show, setShow] = useState(false)
    function handleDelete(id) {
        iziAsk({
            callback: yes => {
                if (yes) {
                    // // Send request to download the file
                    $.ajax({
                        url: "/api/order/deleteattachement?Id=" + id,
                        type: "POST",
                        error: function (xhr, status, error) {
                            console.log(error);
                        }
                    }).then(() => {
                        order.CAttachments = order.CAttachments.filter(c => c.Id !== id);
                        onUpdated(order);
                        iziToast.success({
                            message: "تم الحدف"
                        })
                    });
                }
            }
        })
    }
    function handleDownload({ Id, Name, Ext }) {
        iziAsk({
            message: "هل انت متأكد؟",
            callback: yes => {
                if (yes) {
                    // Send request to download the file
                    $.ajax({
                        url: "/api/order/download?id=" + Id,
                        type: "GET",
                        xhrFields: {
                            responseType: "blob" // Set the response type as blob
                        },
                        success: function (response, status, xhr) {
                            // Create a temporary anchor element
                            var a = document.createElement("a");
                            var url = window.URL.createObjectURL(response);
                            // Set the anchor element's attributes for downloading
                            a.href = url;
                            const fileName = Name + Ext;
                            a.download = fileName;
                            // Append the anchor element to the document body
                            document.body.appendChild(a);
                            // Trigger a click event on the anchor element to start downloading
                            a.click();
                            // Remove the anchor element from the document body
                            document.body.removeChild(a);
                            // Clean up the URL object
                            window.URL.revokeObjectURL(url);
                        },
                        error: function (xhr, status, error) {
                            console.log(error);
                        }
                    });
                }
            }
        })
    }
    return (
        <div className={`list-group ${className ?? ''}`} {...etc}>
            <div className={`list-group-item list-group-item-${theme} d-flex justify-content-between align-items-center`}>المستندات{canManage && <span className={`btn btn-sm btn-${theme} py-0 no-shadow`} onClick={() => setShow(true)}><span className='ml-2'><i className="fa-solid fa-circle-plus mr-2"></i></span>رفع مستند</span>}</div>
            {order.CAttachments && order.CAttachments.length > 0 ? order.CAttachments.map(a => <div key={a.Id} className='list-group-item d-flex align-items-center justify-content-between'><span><i className="fa-solid fa-paperclip ml-1"></i>{a.Name}</span><span className='btn-group' style={{ direction: "ltr" }}><span className='btn btn-sm btn-dark px-2' onClick={() => handleDownload(a)} title='تحميل'><i className="fa-solid fa-download"></i></span><span className="btn btn-sm btn-danger px-2" onClick={() => handleDelete(a.Id)} title="حذف"><i className="fa-solid fa-trash-can"></i></span></span></div>) : <div className='list-group-item text-center text-muted'>لايوجد اي مستند</div>}
            {show &&
                <Formik
                    initialValues={{ Id: 0, Name: '', OrderId: order.Id, file: null }}
                    validationSchema={uploadValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        var formData = new FormData();
                        Object.keys(values).forEach(key => formData.append(key, values[key]));
                        $.ajax({
                            url: "/api/order/addattachment",
                            type: "POST",
                            data: formData,
                            contentType: false,
                            processData: false
                        }).then(resp => {
                            order.CAttachments = [...order.CAttachments, resp];
                            onUpdated(order);
                            setShow(false);
                            resetForm({ Id: 0, Name: '', OrderId: order.Id, file: null })
                            iziToast.success({
                                message: "تم الرفع"
                            })
                        }).catch(err => {
                            iziToast.error({
                                message: "فشل في رفع الملف. الرجاء المحاولة مرة اخرى"
                            })
                        }).always(() => setSubmitting(false));
                    }}
                >
                    {
                        ({ handleSubmit, setFieldValue, isSubmitting, errors }) => (
                            <BModel show={show} setShow={setShow} title="رفع مستند" cancelText="إلغاء" okText="رفع المستند" center size='md' handleSubmit={handleSubmit} isSubmitting={isSubmitting}>
                                <FormInput title="وصف المستند" propName="Name" />
                                {/* <FormInput title="المستند" propName="file" type="file" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*, .pdf, .doc" inputProps={{
                                    onChange: e => {
                                        setFieldValue("file", e.currentTarget.files && e.currentTarget.files[0] ? e.currentTarget.files[0] : null);
                                    }
                                }} /> */}
                                <JustInput invalid={errors.file} title="المستند" type="file" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*, .pdf" placeholder="إضغط لـ إختيار ملف" onChange={e => {
                                    var file = e.currentTarget.files[0];
                                    setFieldValue("file", file);
                                }} />
                            </BModel>
                        )
                    }
                </Formik>}
        </div>
    )
}

