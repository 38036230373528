import React from 'react'
import Logo from '../components/Logo'
import { Link } from 'react-router-dom'

export default function Terms() {
    return (
        <div className='container'>
            <div className="text-center my-5">
                <Link to="/">
                    <Logo width={280} animate />
                </Link>
                <div className="h4 my-5">مؤسسه سلفني</div>
            </div>
            <div className='rtl'>
                <div className="h4 mt-5  text-decoration-underline">
                    <div className="h4 text-center">سياسة الخصوصية</div>
                    <div className="h6 text-center">تاريخ السريان: 04-12-2023</div>
                </div>
                <p className="h6 text-align-justify mt-4">توضح هذه السياسة الخصوصية كيفية جمع [مؤسسه سلفني  (“نحن”، “نحن” أو “نا”) واستخدامها ومشاركتها للمعلومات التي يتم جمعها من المستخدمين (“المستخدم”، “أنت” أو “لك”) لـ [المواقع /محركات البحث].</p>
                <p></p>
                <p className="h5" style={{ textDecoration: "underline" }}>المعلومات التي نجمعها</p>
                <p className='text-align-justify'><span className='bullet'></span><span ><b>المعلومات الشخصية</b>: قد نجمع بعض المعلومات الشخصية، مثل الاسم، عنوان البريد الإلكتروني، رقم الهاتف، أو معرفات مماثلة عندما تقدم هذه المعلومات بإرادتك، على سبيل المثال عند إنشاء حساب أو الاتصال بنا للحصول على الدعم.</span></p>
                <p className="text-align-justify"><span className="bullet"></span><span><b>بيانات الاستخدام</b>: قد نجمع تلقائياً معلومات حول جهازك وكيفية تفاعلك مع التطبيق، مثل معلومات الجهاز (مثل نوع الجهاز ونظام التشغيل)،  أنماط الاستخدام، وسجل التصفح داخل التطبيق.</span></p>
                <p>
                </p>
                <p className="h5" style={{ textDecoration: "underline" }}>كيف نستخدم معلوماتك</p>
                <p>قد نستخدم المعلومات التي تم جمعها لأغراض الاتية:</p>
                <p><span className="bullet"></span>لتوفير وصيانة وظائف التطبيق والخدمات.</p>
                <p><span className="bullet"></span>لتخصيص تجربتك وتحسين تطبيقنا.</p>
                <p><span className="bullet"></span>للتواصل معك والرد على استفساراتك وتقديم الدعم لك.
                </p>
                <p><span className="bullet"></span>لتحليل اتجاهات الاستخدام وتحسين أداء التطبيق.
                </p>
                <p><span className="bullet"></span>لإرسال مواد ترويجية أو تحديثات حول خدماتنا، بموافقتك حيث يُشترط ذلك بموجب القانون المعمول به.
                </p>
                <p><span className="bullet"></span>مشاركة البيانات والكشف عنها
                </p>

                <p></p>
                <p className="h5" style={{ textDecoration: "underline" }}>قد نشارك معلوماتك مع أطراف ثالثة في الحالات الاتية:
                </p>


                <p><span className="bullet"></span>مع مقدمي الخدمات أو البائعين من الأطراف الثالثة الذين يساعدوننا في تشغيل التطبيق وتقديم الخدمات لك.
                </p>
                <p><span className="bullet"></span>ردًا على الطلبات القانونية، أو الأوامر القضائية، أو للامتثال للقوانين واللوائح ذات الصلة.
                </p>
                <p><span className="bullet"></span>إذا تعرضت أعمالنا للاستحواذ أو الاندماج أو البيع، قد يتم نقل معلوماتك كجزء من تلك العملية.
                </p>
                <p className="h5" style={{ textDecoration: "underline" }}>الاحتفاظ بالبيانات

                </p>

                <p><span className="bullet"></span>سنحتفظ بمعلوماتك فقط لأطول فترة ممكنة لأغراض تم تحديدها في هذه السياسة الخصوصية، أو كما يُشترط بموجب القوانين المعمول بها. عندما لا نحتاج إلى معلوماتك بعد الآن، سنقوم بحذفها بشكل آمن أو جعلها مجهولة.</p>

                <p className="h5" style={{ textDecoration: "underline" }}>حقوقك وخياراتك
                </p>
                <p><span className="bullet"></span>يمكنك الوصول إلى المعلومات الشخصية الخاصة بك أو تصحيحها أو حذفها عن طريق الاتصال بنا باستخدام المعلومات المقدمة أدناه.</p>
                <p><span className="bullet"></span>يمكنك الاختيار من عدم تلقي الاتصالات الترويجية منا عن طريق اتباع التعليمات لإلغاء الاشتراك المقدمة في تلك الاتصالات.</p>

                <p className="h5" style={{ textDecoration: "underline" }}>الأمان</p>
                <p><span className="bullet"></span>نتخذ تدابير معقولة لحماية معلوماتك من الوصول غير المصرح به أو الاستخدام أو الكشف عنه. ومع ذلك، يرجى أن تكون على علم بأنه لا يوجد أي طريقة لنقل المعلومات عبر الإنترنت أو التخزين الإلكتروني تكون آمنة تمامًا.</p>
                <p className="h5" style={{ textDecoration: "underline" }}>اتصل بنا
                </p>

                <p><span className="bullet"></span>إذا كان لديك أي أسئلة أو استفسارات بشأن هذه السياسة الخصوصية أو ممارساتنا فيما يتعلق بالبيانات،التواصل رسائل خاصه وسيتم الرد عليك .</p>
                <p className="h5" style={{ textDecoration: "underline" }}>تغييرات على هذه السياسة الخصوصية
                </p>
                <p><span className="bullet"></span>قد نقوم بتحديث هذه السياسة الخصوصية من وقت لآخر. سنقوم بإعلامك بأي تغييرات من خلال نشر السياسة الخصوصية الجديدة على هذه الصفحة.</p>
                <p className="py-5 text-center text-muted-transparent">...</p>
            </div>
        </div>
    )
}
