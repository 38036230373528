import React, { useEffect } from 'react'
import $ from "jquery"
import { useRecoilState } from 'recoil'
import { userState } from '../utilities/app-store';
import iziToast from 'izitoast';
import { useNavigate } from 'react-router-dom';

export default function Notifications() {
    const [user, setUser] = useRecoilState(userState);
    const navigate = useNavigate();
    const handleClick = (event) => {
        event.stopPropagation()
        if (!$(event.currentTarget).hasClass('show')) hide();
        $(event.currentTarget).toggleClass('show').find('.dropdown-menu').toggleClass('show')
    }
    const hide = () => {
        $('li.show').toggleClass('show').find('.show').toggleClass('show')
    }
    useEffect(() => {
        window.addEventListener("click", function () {
            hide()
        })
    }, [])
    function logout() {
        iziToast.question({
            timeout: 10000,
            close: false,
            displayMode: 'once',
            id: 'question',
            zindex: 999,
            layout: 2,
            rtl: true,
            title: 'سؤال تأكيد',
            message: `<div className="my-3">هل انت متأكد من انك تود تسجيل الخروج؟</div>`,
            buttons: [
                ['<button className="text-white"><b>نعم</b></button>', function (instance, toast) {
                    instance.hide({ transitionOut: 'fadeOut' }, toast, true);

                }, true],
                ['<button className="text-white">إلغاء</button>', function (instance, toast) {
                    instance.hide({ transitionOut: 'fadeOut' }, toast);
                }],
            ],
            position: 'center',
            overlay: true,
            backgroundColor: "#34395e",
            titleColor: "#fff",
            messageColor: "#f4f4f4",
            iconColor: "#ffff",
            color: "#fff",
            onClosing: function (instance, toast, isYes) {
                if (isYes === true) {
                    setTimeout(() => {
                        setUser(null);
                        navigate("/")
                    }, 500)
                }
            }
        });
    }
    if (!user) return null;
    return (
        <>
            {/* <li className="ml-auto dropdown dropdown-list-toggle" onClick={handleClick}>
                <a data-toggle="dropdown" className="nav-link notification-toggle nav-link-lg beep"><i className="far fa-bell"></i></a>
                <div className="dropdown-menu dropdown-list dropdown-menu-right">
                    <div className="dropdown-header">الإشعارات

                    </div>
                    <div className="dropdown-list-content dropdown-list-icons">
                        <a className="dropdown-item dropdown-item-unread">
                            <div className="dropdown-item-icon bg-primary text-white">
                                <i className="fas fa-code"></i>
                            </div>
                            <div className="dropdown-item-desc">
                                Template update is available now!
                                <div className="time text-primary">2 Min Ago</div>
                            </div>
                        </a>
                        <a className="dropdown-item">
                            <div className="dropdown-item-icon bg-info text-white">
                                <i className="far fa-user"></i>
                            </div>
                            <div className="dropdown-item-desc">
                                <b>You</b> and <b>Dedik Sugiharto</b> are now friends
                                <div className="time">10 Hours Ago</div>
                            </div>
                        </a>
                        <a className="dropdown-item">
                            <div className="dropdown-item-icon bg-success text-white">
                                <i className="fas fa-check"></i>
                            </div>
                            <div className="dropdown-item-desc">
                                <b>Kusnaedi</b> has moved task <b>Fix bug header</b> to <b>Done</b>
                                <div className="time">12 Hours Ago</div>
                            </div>
                        </a>
                        <a className="dropdown-item">
                            <div className="dropdown-item-icon bg-danger text-white">
                                <i className="fas fa-exclamation-triangle"></i>
                            </div>
                            <div className="dropdown-item-desc">
                                Low disk space. Let's clean it!
                                <div className="time">17 Hours Ago</div>
                            </div>
                        </a>
                        <a className="dropdown-item">
                            <div className="dropdown-item-icon bg-info text-white">
                                <i className="fas fa-bell"></i>
                            </div>
                            <div className="dropdown-item-desc">
                                Welcome to Stisla template!
                                <div className="time">Yesterday</div>
                            </div>
                        </a>
                    </div>
                    <div className="dropdown-footer text-center">
                        <a>View All <i className="fas fa-chevron-right"></i></a>
                    </div>
                </div>
            </li> */}
            <li className="dropdown ml-auto" onClick={handleClick}>
                <a data-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user">
                    <img alt="image" src="https://demo.getstisla.com/assets/img/avatar/avatar-1.png" className="rounded-circle mr-1" />
                    <div className="d-sm-none d-lg-inline-block">هلا {user?.Name}</div></a>
                <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-title">{user?.Email}</div>
                    <a className="dropdown-item has-icon" onClick={() => {
                        navigate("/profile")
                    }}>
                        <i className="far fa-user"></i> الملف الشخصي
                    </a>
                    <a className="dropdown-item has-icon" onClick={() => navigate("/settings")}>
                        <i className="fas fa-cog"></i> الإعدادات
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item has-icon text-danger" onClick={logout}>
                        <i className="fas fa-sign-out-alt"></i> تسجيل الخروج
                    </a>
                </div>
            </li>
        </>
    )
}
