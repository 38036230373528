import React, { useMemo } from 'react'
import DHead from '../components/DHead'
import DefaultTable from './../components/DTable';
import * as Yup from "yup"
import FormInput from './../components/FormInput';
import FormInputWithIcon from './../components/FormInputWithIcon';
import FormSelect from '../components/FormSelect';
import { maxLen } from '../utilities/functions';
import FormCheckBox from '../components/FormCheckBox';
import { useRecoilValue } from 'recoil'
import { userState } from '../utilities/app-store';
import { ibanNumber, requiredIdNumber } from '../utilities/validations';
import IBANInput from '../components/IBANInput';


// validation schema
const validationSchema = Yup.object().shape({
    Name: Yup.string().required("اكتب اسمك!").max(200, maxLen(200)),
    Email: Yup.string().email("البريد الإلكتروني غير صالح").required("بريدك الإلكتروني لايمكن ان يكون فارغا").max(256, maxLen(256)),
    PhoneNumber: Yup.string().required("وش رقم تلفونك؟").max(9, maxLen(9)).matches(
        /^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
        "رقم الهاتف غير صحيح"
    ),
    IdentityNumber: requiredIdNumber,
    // Occupation: Yup.string().max(200, maxLen(200)),
    // WorkPlace: Yup.string().max(200, maxLen(200)),
    // BankName: Yup.string().max(200, maxLen(200)),
    IBANNumber: ibanNumber,
    // Address: Yup.string().max(150, maxLen(150)),
    // City: Yup.string().max(70, maxLen(70)),
})

// table columns
const columns = [
    { Header: "الأسم", accessor: 'Name', isSorted: true, Cell: ({ value, row }) => (<>{row.original.Role === "Client" ? <i className="fa-solid fa-hand-holding-dollar mx-3"></i> : <i className="fa-solid fa-user-tie mx-3"></i>}{value}</>) },
    { Header: "الهاتف", accessor: 'PhoneNumber' },
    { Header: "الايميل", accessor: 'Email' },
    { Header: "الهوية|الإقامة", accessor: 'IdentityNumber' },
    { Header: "محذوف", accessor: 'Deleted' },
]

const FormContent = ({ values }) => {
    const user = useRecoilValue(userState);
    const isNew = useMemo(() => values.Id == 0, []);
    const emailProps = useMemo(() => isNew ? {} : { disabled: true }, []);
    const roleProps = useMemo(() => isNew ? {} : { disabled: values.Role === "Client" || !"Admin Developer".includes(user.roles[0]) }, []);
    return (
        <>
            <FormInput title="الأسم" required propName="Name" containerClassName="col-md-6" />
            <FormInput title="البريد الإلكتروني" required propName="Email" containerClassName="col-md-6" inputProps={emailProps} />
            <FormInputWithIcon title="رقم الهاتف" required className="no-spinner text-left" containerClassName="col-md-6" prepend="+966" propName="PhoneNumber" maxLength={9} />
            <FormInput title="رقم الهوية | الإقامة" required propName="IdentityNumber" maxLength={100} containerClassName="col-md-6" />
            <FormInput title="العنوان" propName="Address" maxLength={150} containerClassName="col-md-6" />
            <FormInput title="المدينة" propName="City" maxLength={70} containerClassName="col-md-6" />
            <FormInput title="المهنة" propName="Occupation" maxLength={200} containerClassName="col-md-6" />
            <FormInput title="جهة العمل" propName="WorkPlace" maxLength={200} containerClassName="col-md-6" />
            <FormInput title="إسم البنك" propName="BankName" maxLength={200} containerClassName="col-md-6" />
            <IBANInput containerClassName='col-md-6' />
            {/* <FormInput title="رقم الحساب IBAN" propName="IBANNumber" maxLength={200} containerClassName="col-md-6" /> */}
            <FormSelect inputProps={roleProps} propName="Role" title="الصلاحيات" containerClassName="col-md-6">
                <option value="Admin">إدارة</option>
                <option value="Editor">تعديل</option>
                <option value="Read">قراءة فقط</option>
                {!isNew && <option value="Client">عميل</option>}
            </FormSelect>
            <FormCheckBox propName="LockoutEnabled" title="جمد الحساب" />
            {/* LockoutEnd */}
        </>
    )
}

export default function Users() {
    return (

        <DHead title="المستخدمين">
            <DefaultTable
                columns={columns}
                url="/api/user"
                schema={validationSchema}
                FormContent={FormContent}
                defaultValues={{
                    Id: 0,
                    Name: '',
                    PhoneNumber: "534444560",
                    Email: '',
                    Role: 'Read',
                    IdentityNumber: '',
                    LockoutEnabled: false,
                }}
                editorProps={{
                    size: "lg"
                }}
                deleteUrl="/api/user/deleteuser"
            />
        </DHead>
    )
}
