import React from 'react'
import DHead from '../components/DHead'
import { Formik } from 'formik'
import FormCheckBox from '../components/FormCheckBox';

export default function Settings() {
    return (
        <DHead title="الإعدادات">
            {/* <div className="row">
                <div className="col-lg-6">
                    <div className="card card-large-icons">
                        <div className="card-icon bg-primary text-white">
                            <i className="fas fa-cog"></i>
                        </div>
                        <div className="card-body">
                            <h4>General</h4>
                            <p>General settings such as, site title, site description, address and so on.</p>
                            <a href="features-setting-detail.html" className="card-cta">Change Setting <i className="fas fa-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-large-icons">
                        <div className="card-icon bg-primary text-white">
                            <i className="fas fa-search"></i>
                        </div>
                        <div className="card-body">
                            <h4>SEO</h4>
                            <p>Search engine optimization settings, such as meta tags and social media.</p>
                            <a href="features-setting-detail.html" className="card-cta">Change Setting <i className="fas fa-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-large-icons">
                        <div className="card-icon bg-primary text-white">
                            <i className="fas fa-envelope"></i>
                        </div>
                        <div className="card-body">
                            <h4>Email</h4>
                            <p>Email SMTP settings, notifications and others related to email.</p>
                            <a href="features-setting-detail.html" className="card-cta">Change Setting <i className="fas fa-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-large-icons">
                        <div className="card-icon bg-primary text-white">
                            <i className="fas fa-power-off"></i>
                        </div>
                        <div className="card-body">
                            <h4>System</h4>
                            <p>PHP version settings, time zones and other environments.</p>
                            <a href="features-setting-detail.html" className="card-cta">Change Setting <i className="fas fa-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-large-icons">
                        <div className="card-icon bg-primary text-white">
                            <i className="fas fa-lock"></i>
                        </div>
                        <div className="card-body">
                            <h4>Security</h4>
                            <p>Security settings such as firewalls, server accounts and others.</p>
                            <a href="features-setting-detail.html" className="card-cta">Change Setting <i className="fas fa-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-large-icons">
                        <div className="card-icon bg-primary text-white">
                            <i className="fas fa-stopwatch"></i>
                        </div>
                        <div className="card-body">
                            <h4>Automation</h4>
                            <p>Settings about automation such as cron job, backup automation and so on.</p>
                            <a href="features-setting-detail.html" className="card-cta text-primary">Change Setting <i className="fas fa-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="card shadow-sm rtl">
                <div className="card-header text-center">إعدادات الموقع والبرنامج :</div>
                <div className="card-body">
                    <Formik
                        initialValues={{
                            disableApp: false,
                            disableWeb: false,
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                        }}
                    >
                        <div className='d-flex flex-column'>
                            <FormCheckBox propName="disableWeb" title="تعطيل الموقع" />
                            <FormCheckBox propName="disableApp" title="تعطيل البرنامج" />
                        </div>
                    </Formik>
                </div>
            </div>
        </DHead>
    )
}
