import React from 'react'
import "./MadeWithLove.css"

export default function MadeWithLove(props) {
    return (
        <span {...props}>
            Made with <i className="fa fa-heart pulse text-danger heart"></i> by <b><a href="https://twitter.com/AbuMaxeem" target="_blank" {...props}>αϐυ мαϰєєм</a></b>
        </span>
    )
}
