import React, { useEffect, useMemo, useRef } from 'react'

export default function Logo({ light, animate, ...etc }) {
    const theme = useMemo(() => light ? " light" : "", []);
    const svg = useRef(null)
    useEffect(() => {
        svg.current.classList.add("active");
    }, [])
    return (
        // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163.59 59.01" {...etc}><line className={`cls-1 ${theme}`} x1="135.39" y1="39.65" x2="135.39" y2="52.43" /><line className={`cls-1 ${theme}`} x1="147.99" y1="34.17" x2="147.99" y2="52.43" /><line className={`cls-1 ${theme}`} x1="122.8" x2="122.8" y2="52.43" /><line className={`cls-1 ${theme}`} x1="85.01" y1="29.22" x2="85.01" y2="52.43" /><polyline className={`cls-1 ${theme}`} points="110.2 40.83 97.61 40.83 97.61 39.83 97.61 32.3 110.2 32.3 110.2 52.44" /><polyline className={`cls-1 ${theme}`} points="18.5 55.03 62.49 55.03 62.49 52.12 62.49 32.61 74.92 32.61 74.92 52.12 74.92 55.03 160.59 55.03 160.59 52.12 160.59 29.62" /><circle className={`cls-2 ${theme}`} cx="103.9" cy="23.09" r="2.48" /><circle className={`cls-2 ${theme}`} cx="68.43" cy="23.74" r="2.48" /><circle className={`cls-2 ${theme}`} cx="12.59" cy="55.03" r="2.48" /><circle className={`cls-2 ${theme}`} cx="3.98" cy="55.03" r="2.48" /></svg>
        <svg xmlns="http://www.w3.org/2000/svg" id='solfalogo' className={animate ? "" : "active"} ref={svg} viewBox="0 0 163.59 59.01" {...etc}>
            <line className={`cls-1 svg-elem-1 ${theme}`} x1="135.39" y1="39.65" x2="135.39" y2="52.43" />
            <line className={`cls-1 svg-elem-2 ${theme}`} x1="147.99" y1="34.17" x2="147.99" y2="52.43" />
            <line className={`cls-1 svg-elem-3 ${theme}`} x1="122.8" x2="122.8" y2="52.43" />
            <line className={`cls-1 svg-elem-4 ${theme}`} x1="85.01" y1="29.22" x2="85.01" y2="52.43" />
            <polyline className={`cls-1 svg-elem-5 ${theme}`} points="110.2 40.83 97.61 40.83 97.61 39.83 97.61 32.3 110.2 32.3 110.2 52.44" />
            <polyline className={`cls-1 svg-elem-6 ${theme}`} points="18.5 55.03 62.49 55.03 62.49 52.12 62.49 32.61 74.92 32.61 74.92 52.12 74.92 55.03 160.59 55.03 160.59 52.12 160.59 29.62" />
            <circle className={`cls-2 svg-elem-7 ${theme}`} cx="103.9" cy="23.09" r="2.48" />
            <circle className={`cls-2 svg-elem-8 ${theme}`} cx="80.35" cy="23.09" r="2.48" />
            <circle className={`cls-2 svg-elem-9 ${theme}`} cx="12.59" cy="55.03" r="2.48" />
            <circle className={`cls-2 svg-elem-10 ${theme}`} cx="3.98" cy="55.03" r="2.48" />
        </svg>
    )
}
