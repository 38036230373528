import React, { useEffect } from 'react'
import DHead from '../components/DHead'
import DefaultTable from '../components/DTable';
import * as Yup from "yup"

import FormInput from '../components/FormInput';
import FormCheckBox from '../components/FormCheckBox';
import { ResizeImages, ClearBase64Url, maxLen } from '../utilities/functions';
import $ from "jquery"

const notEmpty = "لايمكن ان يكون هذا الحقل فارغ";

const min0 = "اقل قيمة للحقل هي 0";
// validation schema
const validationSchema = Yup.object().shape({
    Name: Yup.string().required(notEmpty).max(100, maxLen(100)),
    Description: Yup.string().required(notEmpty).max(200, maxLen(200)),
    Price: Yup.number().min(0).required(notEmpty).min(0, min0),
    IntrestRate: Yup.number().min(0).required(notEmpty).min(0, min0),
})
// table columns
const columns = [
    { Header: "الأسم", accessor: 'Name', isSorted: true },
    { Header: "الوصف", accessor: 'Description' },
    { Header: "السعر", accessor: 'Price' },
    { Header: "سعر الفائدة", accessor: 'IntrestRate' },
    { Header: "اظهار كـ بانر", accessor: 'IsSlide', Cell: ({ value, row }) => value ? <i className="fa-regular fa-circle-check"></i> : null },
]
//"Id", "Name", "Description", "Price", "IntrestRate", "Photo", "IsSlide"

function FormContent({ values, setFieldValue }) {

    useEffect(() => {
        if (values.Photo) {
            $('img[data-service-img]').attr('src', "/content/" + values.Photo);
        }
    }, [])
    return (
        <>
            <div className="col-12 col-md-6">
                <FormInput required propName="Name" title="الأسم" />
            </div>
            <div className="col-12 col-md-6">
                <FormInput required propName="Description" title="الوصف" />
            </div>
            <div className="col-12 col-md-6">
                <FormInput required propName="Price" title="السعر" />
            </div>
            <div className="col-12 col-md-6">
                <FormInput required propName="IntrestRate" title="سعر الفائدة" />
            </div>
            <div className="col-12 col-md-6">
                <FormCheckBox propName="IsSlide" title="إظهار كـ بانر" />
            </div>
            <div className="col-12">
                <div className="list-group">
                    <div className="list-group-item active text-center">
                        الصورة
                    </div>
                    <div className="list-group-item p-0 position-relative" onClick={() => {
                        $("[data-adel-naje-ahmed]").click();
                    }} title='' style={{ height: "300px", cursor: "pointer" }}>
                        <input className="form-control d-none" data-adel-naje-ahmed type="file" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" onChange={(event) => {
                            const f = event.target.files[0];
                            ResizeImages(f, dataUrl => {
                                setFieldValue("File", ClearBase64Url(dataUrl));
                                $('img[data-service-img]').attr('src', dataUrl);
                                // $('span[data-delete-img-button]').show('fast');
                            })
                        }} />
                        <img data-service-img alt='ًالصورة' className={values.File || values.Photo ? "" : "d-none"} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                        <span className={`btn btn-danger rounded-5 position-absolute ${values.File || values.Photo ? "" : "d-none"}`} onClick={event => {
                            event.stopPropagation();
                            setFieldValue("File", null);
                            setFieldValue("Photo", null);
                            $('[data-adel-naje-ahmed]').val(null).change();
                            $('img[data-service-img]').attr('src', '');
                        }} style={{ top: "20px", left: "20px" }} ><i className="fa-solid fa-trash"></i></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default function Items() {
    return (
        <DHead title="الاصناف">
            <DefaultTable
                columns={columns}
                url="/api/item"
                schema={validationSchema}
                FormContent={FormContent}
                defaultValues={{ Id: 0, Name: '', Description: '', Price: "0", IntrestRate: "0", IsSlide: false }}
                editorProps={{
                    size: "lg"
                }}
            />
        </DHead>
    )
}
