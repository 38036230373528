import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import $ from 'jquery'
import CopyRightsText from '../components/CopyRightsText';
import LoadingSpinner from './../components/LoadingSpinner';
export default function ConfirmEmail() {
    //const navigate= useNavigate();
    let [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    const [resp, setResp] = useState(null);

    useEffect(() => {
        if (email && token) {
            $.ajax({ url: '/api/manage/confirmemail', data: { token, email } }).then(response => {
                setResp(response);
            }).catch((err) => {
                setResp('لم نستطع اكمال العملية . قد يكون رابط التأكيد منتهي . الرجاء المحاولة مرة اخرى')
            })
        } else {
            setResp('لم نستطع اكمال العملية . قد يكون رابط التأكيد منتهي . الرجاء المحاولة مرة اخرى')
        }
    }, [])

    return (
        <section className="section">
            <div className="container mt-5">
                <div className="row text-right">
                    <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2">
                        <div className="login-brand">
                            <img src="/content/logo.svg" alt="logo" width="100" className="shadow-light rounded-circle" />
                        </div>

                        <div className="card card-primary">
                            <div className="card-header justify-content-center">
                                <h4>تأكيد البريد الإلكتروني</h4>
                            </div>
                            <div className="card-body rtl">
                                <div className="py-3 text-center">
                                    {resp != null ? <><span className='p-5 text-primary'>{resp}</span><div className="text-center p-5">
                                        <Link to="/">قم بتسجيل الدخول</Link>
                                    </div></> : <LoadingSpinner />}
                                </div>

                            </div>
                        </div>
                        <div className="simple-footer">
                            <CopyRightsText />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
