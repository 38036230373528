import React, { useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { userState } from './../utilities/app-store';
import BModel from './BModel';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormInput from './FormInput';
import FormTextArea from './FormTextArea';
import $ from "jquery"
import iziToast from 'izitoast';

export default function UserCard({ Name, Email, IBANNumber, children, Address, PhoneNumber, IdentityNumber, Occupation,
    WorkPlace, BankName, City, Total, Completed, Progress, containerProps }) {
    const user = useRecoilValue(userState)
    const same = useMemo(() => user?.Email === Email, [user])


    return (
        <div className="card profile-widget shadow-none py-0" {...containerProps}>
            <div className="profile-widget-header">
                <img alt="image" src="https://demo.getstisla.com/assets/img/avatar/avatar-1.png" className="rounded-circle profile-widget-picture" style={{ cursor: "pointer" }} title='اضفط لتغيير الصورة' />
                <div className="profile-widget-items d-flex justify-content-center">إحصائيات الطلبات</div>
                <div className="profile-widget-items">
                    <div className="profile-widget-item">
                        <div className="profile-widget-item-label text-center">الاجمالي</div>
                        <div className="profile-widget-item-value text-center mt-2">{Total}</div>
                    </div>
                    <div className="profile-widget-item">
                        <div className="profile-widget-item-label text-center"> المكتملة</div>
                        <div className="profile-widget-item-value text-center mt-2">{Completed}</div>
                    </div>
                    <div className="profile-widget-item">
                        <div className="profile-widget-item-label text-center"> قيد المراجعة</div>
                        <div className="profile-widget-item-value text-center mt-2">{Progress}</div>
                    </div>
                </div>
                {/* <input className="form-control d-none" type="file" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*" onChange={(event) => {
                                const f = event.target.files[0];
                                // ResizeImages(f, dataUrl => {
                                //     setFieldValue("File", ClearBase64Url(dataUrl));
                                //     $('img[data-service-img]').attr('src', dataUrl);
                                //     // $('span[data-delete-img-button]').show('fast');
                                // })
                            }} /> */}
            </div>
            <div className="profile-widget-description">
                <div className="profile-widget-name">{Name} <div className="text-muted d-inline font-weight-normal"><div className="slash"></div> {Email}</div></div>
                {Address != null && Address?.length > 0 && <><span>يعيش في <b className='mx-2'>{Address}</b>,<b>{City}</b></span><br /></>}
                {PhoneNumber != null && PhoneNumber?.length > 0 && <><span>رقم الهاتف <b className='mx-2'>{PhoneNumber}</b></span><br /></>}
                {IdentityNumber != null && IdentityNumber?.length > 0 && <><span>رقم الوطني | الإقامة <b className='mx-2'>{IdentityNumber}</b></span><br /></>}
                {Occupation != null && Occupation?.length > 0 && <><span> المهنة <b className='mx-2'>{Occupation}</b></span><br /></>}
                {WorkPlace != null && WorkPlace?.length > 0 && <><span> جهة العمل <b className='mx-2'>{WorkPlace}</b></span><br /></>}
                {BankName != null && BankName?.length > 0 && <><span> حساب بنكي في <b className='mx-2'>{BankName}</b></span>{IBANNumber != null && IBANNumber?.length > 0 && <><span> ، برقم <b className='mx-2'>{IBANNumber}</b></span><br /></>}</>}
                {children}
            </div>
            {!same &&
                <div className="card-body d-flex justify-content-between">
                    <SendEmail email={Email} />
                    {PhoneNumber && <SendSMS phoneNumber={PhoneNumber} />}
                </div>
            }
        </div>
    )
}

const newEmailValidation = Yup.object().shape({
    subject: Yup.string().required("لايمكن ترك العنوان فارغاً"),
    message: Yup.string().required("لايمكن ان يكون نص الرسالة فارغاً"),
})


const SendEmail = ({ email }) => {
    const [show, setShow] = useState(false)
    return (
        <>
            <span className='btn btn-outline-primary align-items-center' onClick={() => setShow(true)}><i className="fa-regular fa-envelope"></i> إرسال بريد إلكتروني</span>
            {show && <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{ email, subject: "", message: "" }}
                validationSchema={newEmailValidation}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    $.ajax({
                        url: "/api/user/sendemail",
                        method: "POST",
                        data: values
                    }).then(() => {
                        iziToast.success({
                            message: "تم إرسال الرسالة بنجاح"
                        })
                        setShow(false);
                    }).always(setSubmitting(false))
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <BModel isSubmitting={isSubmitting} show={show} setShow={setShow} title={`إرسال بريد إلكتروني : ${email}`} okText={<span><i className="fa-regular fa-paper-plane ml-2"></i>إرسال</span>} cancelText="إلغاء" handleSubmit={handleSubmit}>
                        <FormInput propName="subject" title="عنوان الرسالة" />
                        <FormTextArea propName="message" title="نص الرسالة" style={{ minHeight: "200px" }} />
                    </BModel>
                )}
            </Formik>}
        </>
    )
}

const SendSMS = ({ phoneNumber }) => {
    const [show, setShow] = useState(false)
    return (
        <>
            <span className='btn btn-outline-primary align-items-center' onClick={() => setShow(true)}><i className="fa-solid fa-comment-sms"></i> إرسال رسالة نصية</span>
            {show && <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{ phoneNumber, subject: "sms", message: "" }}
                validationSchema={newEmailValidation}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    $.ajax({
                        url: "/api/user/sendsms",
                        method: "POST",
                        data: values
                    }).then(() => {
                        iziToast.success({
                            message: "تم إرسال الرسالة بنجاح"
                        })
                        setShow(false);
                    }).always(setSubmitting(false))
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <BModel isSubmitting={isSubmitting} show={show} setShow={setShow} title={`إرسال رسالة نصية`} okText={<span><i className="fa-regular fa-paper-plane ml-2"></i>إرسال</span>} cancelText="إلغاء" handleSubmit={handleSubmit}>
                        <FormTextArea propName="message" title="نص الرسالة" style={{ minHeight: "200px" }} />
                    </BModel>
                )}
            </Formik>}
        </>
    )
}