import React, { useEffect, useState } from 'react'
import DHead from '../components/DHead'
import $ from "jquery"
import FormInput from '../components/FormInput';
import { Formik } from "formik"
import RegisterForm from '../components/RegisterForm';
import * as Yup from "yup"
import { ShallowEqual, consts } from '../utilities/functions';
import iziToast from 'izitoast';
import LoadingSpinner from '../components/LoadingSpinner';
import UserCard from '../components/UserCard';
import { ibanNumber, idNumber, requiredPhoneNumber,requiredIdNumber } from '../utilities/validations';


const validationSchema = Yup.object({
    Name: Yup.string().required("اكتب اسمك!").test('len', 'لايمكن ان يكون عدد احرف الحقل اكثر من 200 حرف', val => !val || val.length <= 200),
    IdentityNumber: requiredIdNumber,
    Occupation: Yup.string().test('len', 'لايمكن ان يكون عدد احرف الحقل اكثر من 200 حرف', val => !val || val.length <= 200),
    WorkPlace: Yup.string().test('len', 'لايمكن ان يكون عدد احرف الحقل اكثر من 200 حرف', val => !val || val.length <= 200),
    BankName: Yup.string().test('len', 'لايمكن ان يكون عدد احرف الحقل اكثر من 200 حرف', val => !val || val.length <= 200),
    IBANNumber: ibanNumber,
    PhoneNumber: requiredPhoneNumber,
    // Password: Yup.string().required("الرجاء اختيار رمز سري").min(8, "يجب ان لايكون الرمز السري اقل من 8 احرف او ارقام"),
    // ConfirmPassword: Yup.string().required("لايمكن ان يكون الحقل فارغاً").oneOf([Yup.ref('Password')], "لايتطابق مع رمز المرور!")
});
export default function Profile() {
    const [showEditor, setShowEditor] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        if (consts.userData) {
            setUserData(consts.userData)
            setLoading(false);
        } else {
            $.ajax("/api/user/profile").then(data => {
                consts.userData = data;
                setUserData(data)
                setLoading(false);
            })
        }
    }, [])
    if (loading || !userData) return <LoadingSpinner />;
    return (
        <DHead title="الملف الشخصي">
            <div className="row">
                <div className="col-12 col-md-12 col-lg-5">
                    {userData !== null && <UserCard {...userData}>
                        <br />
                        <br />
                        {!showEditor && <button className="btn btn-primary" type="button" onClick={() => setShowEditor(true)}>تعديل المعلومات</button>}
                    </UserCard>}

                </div>
                {showEditor && <div className="col-12 col-md-12 col-lg-7 rtl">
                    <div className="card">
                        <Formik
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                if (ShallowEqual(values, userData)) {
                                    setShowEditor(false);
                                } else {
                                    $.ajax({
                                        url: "/api/user/profile",
                                        data: values,
                                        method: "POST"
                                    }).then(() => {
                                        setUserData(values);
                                        consts.userData = values;
                                        iziToast.success({
                                            message: "تم الحفظ بنجاح"
                                        })
                                        setShowEditor(false);
                                    }).catch(console.log).always(() => setSubmitting(false))
                                }

                            }}
                            initialValues={{ ...userData }}
                        >
                            {({ handleSubmit, isSubmitting }) => (
                                <form method="post" className="needs-validation" onSubmit={handleSubmit}>
                                    <div className="card-header">
                                        <h4>تعديل الملف الشخصي</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <RegisterForm />
                                        </div>
                                    </div>
                                    <div className="card-footer text-right">
                                        <button className="btn btn-primary" disabled={isSubmitting} type="submit">حفظ</button>
                                    </div>
                                </form>
                            )}
                        </Formik>

                    </div>
                </div>}

            </div>
        </DHead>
    )
}
