import React, { useMemo, useState } from 'react'
import CopyRightsText from '../components/CopyRightsText';
import FormInput from '../components/FormInput';
import { Formik } from "formik"
import * as Yup from "yup"
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { userState } from '../utilities/app-store';
import $ from "jquery"
import iziToast from 'izitoast';
import { consts } from '../utilities/functions';
import Logo from '../components/Logo';


const bgs = [
  { url: "02.jpg", name: "ابوظبي، الإمارات العربية المتحدة" },
  { url: "01.jpg", name: "الرياض، المملكة العربية السعودية" },
]

const validationSchema = Yup.object({
  username: Yup.string().email("يجب ان يكون بريد الكتروني صالح").required("لايمكن ان يكون البريد الإلكتروني فارغاً"),
  password: Yup.string().min(8, "يجب ان تكون احرف رمز المرور اكثر من 8 احرف ").required("لايمكن ان يكون رمز المرور فارغاً")
})

function LoginPage() {
  const setUser = useSetRecoilState(userState)
  const bg = useMemo(() => {
    return bgs[Math.floor(Math.random() * 2)]
  }, []);
  // client saad@email.com
  //client ebrahim@email.com
  // developer adel@email.com
  //12345678
  return (
    <section className="section">
      <div className="d-flex flex-wrap align-items-stretch">
        <div className="col-lg-4 col-md-6 col-12 order-lg-1 min-vh-100 order-2 bg-white">
          <div className="p-4 m-3">
            <div className="text-center">
              <Logo width={280} animate />
              {/* <img src="/content/logo.svg" alt="logo" width="280" className="mb-5 mt-2" /> */}
              <h4 className="text-dark font-weight-normal mt-5">مرحبا بكم</h4>
              <p className="text-muted jrtl">قبل البدء، يجب عليك تسجيل الدخول او التسجيل إذا لم يكن لديك حساب بالفعل.</p>
            </div>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={validationSchema}
              initialValues={{ username: "", password: "" }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                $.ajax({
                  url: "/api/login/signin",
                  data: values,
                  headers: { 'x-gs-web': 'AdelCo' }
                }).then(data => {
                  if (!data.EmailConfirmed || data.LockoutEnabled) {
                    iziToast.warning({
                      message: data.LockoutEnabled ? "الحساب مقفل، الرجاء الاتصال بإدارة الموقع للمراجعة" : "لم يتم تأكيد بريدك الالكتروني. الرجاء مراجعة بريدك الالكتروني وتفعيل حسابك",
                      position: "center",
                    })
                    resetForm();
                    return;
                  }
                  consts.user = data;
                  setUser(data);
                }).catch(error => {
                  iziToast.error({
                    message: "لايوجد حساب مطابق للمعلومات المذكورة",
                    position: "center",
                  })
                }).always(() => {
                  setSubmitting(false);
                })
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form className="rtl mt" onSubmit={handleSubmit} noValidate>
                  <FormInput propName="username" title="البريد الإلكتروني" type='email' />
                  <FormInput propName="password" title="رمز المرور" type="password" />
                  <div className="form-group text-right">
                    <Link to="/resetpassword" tabIndex={-1} className="float-left mt-3">
                      نسيت كلمة المرور؟
                    </Link>
                    <button disabled={isSubmitting} type="submit" className="btn btn-primary btn-lg btn-icon icon-right" >
                      تسجيل الدخول
                    </button>
                  </div>
                  <div className="mt-5 text-center">

                    لايوجد لديك حساب ؟ <Link to="register">قم بإنشاء حساب جديد</Link>
                  </div>
                </form>
              )}
            </Formik>

            <div className="text-center mt-5 text-small">
              <div className="mt-2">
                <Link to="/terms">سياسة الخصوصية
                  <span className="bullet"></span>
                  و شروط الخدمة</Link>
              </div>
              <CopyRightsText className="mt-4" />

            </div>
          </div>
        </div>
        <div className="col-lg-8 col-12 order-lg-2 order-1 min-vh-100 background-walk-y position-relative overlay-gradient-bottom login-bg" style={{ backgroundImage: `url("/content/bg/${bg.url}")` }}>
          <div className="absolute-bottom-left index-2">
            <div className="text-light p-5 pb-2">
              <div className="mb-5 pb-3">
                <h1 className="mb-2 display-4 font-weight-bold">{greating()}</h1>
                <h5 className="font-weight-normal text-muted-transparent">{bg.name}</h5>
              </div>
              {/* Photo by <a className="text-light bb" target="_blank" href="https://unsplash.com/photos/a8lTjWJJgLA">Justin Kauffman</a> on <a className="text-light bb" target="_blank" href="https://unsplash.com">Unsplash</a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoginPage

const greating = () => new Date().getHours() > 12 ? "مساء الخير" : "صباح الخير";