import { useFormikContext } from 'formik'
import React from 'react'

export default function FormCheckBox({ propName, inputProps, containerClassName, title }) {
    const { isSubmitting, values, setFieldValue } = useFormikContext();
    return (
        <label className={`custom-switch mt-2 ${containerClassName ?? ""}`}>
            <input type="checkbox" className="custom-switch-input" disabled={isSubmitting} onChange={e => {
                setFieldValue(propName ?? title, e.target.checked)
                // handleChange(propName ?? title)
            }} checked={values[propName ?? title]} {...inputProps} />
            <span className="custom-switch-indicator"></span>
            <span className="custom-switch-description px-2">{title}</span>
        </label>
    )
}
