import React from 'react'
import FormInput from './FormInput'
import FormInputWithIcon from './FormInputWithIcon'
import IBANInput from './IBANInput'


export default function RegisterForm({ isNew = false }) {
    return (
        <>
            <FormInput title="الأسم" required propName="Name" containerClassName="col-md-6" />
            {isNew ? <>
                <FormInput title="البريد الإلكتروني" required propName="Email" containerClassName="col-md-6" />
                <FormInput title="رمز المرور" type="password" required propName="Password" containerClassName="col-md-6" />
                <FormInput title="تأكيد رمز المرور" type="password" required propName="ConfirmPassword" containerClassName="col-md-6" />
            </> : null}
            <FormInputWithIcon title="رقم الهاتف" maxLength={9} type="number" required className="no-spinner text-left" containerClassName="col-md-6" prepend="+966" propName="PhoneNumber" />
            <FormInput title="العنوان" propName="Address" containerClassName="col-md-6" />
            <FormInput title="المدينة" propName="City" containerClassName="col-md-6" />
            <FormInput title="رقم الهوية | الإقامة" propName="IdentityNumber" containerClassName="col-md-6" />
            <FormInput title="المهنة" propName="Occupation" containerClassName="col-md-6" />
            <FormInput title="جهة العمل" propName="WorkPlace" containerClassName="col-md-6" />
            <FormInput title="إسم البنك" propName="BankName" containerClassName="col-md-6" />
            <IBANInput containerClassName='col-md-6' />
        </>
    )
}
