import React, { useEffect, useMemo, useState } from 'react'
import DHead from '../components/DHead'
import $ from "jquery"
import { Link } from 'react-router-dom'
import { DateTimeArabic, orderStatus } from '../utilities/functions'

export default function AdminDashboard() {
    const [data, setData] = useState({
        orders: [],
        stastics: []
    })
    const monthOrders = useMemo(() => {
        return data.stastics.reduce((ac, i) => ac += i.Count, 0);
    }, [data])
    const studyOrders = useMemo(() => {
        return data.stastics.reduce((ac, i) => {
            if ([0, 1, 2].includes(i.Status)) ac += i.Count;
            return ac;
        }, 0);
    }, [data])
    const agreedOrders = useMemo(() => {
        return data.stastics.reduce((ac, i) => {
            if ([3, 4, 5, 6, 7].includes(i.Status)) ac += i.Count;
            return ac;
        }, 0);
    }, [data])
    const rejectedOrders = useMemo(() => {
        return data.stastics.reduce((ac, i) => {
            if ([8, 9].includes(i.Status)) ac += i.Count;
            return ac;
        }, 0);
    }, [data])
    useEffect(() => {
        $.ajax("/api/order/dashboard").then(resp => {
            setData(resp)
        })
    }, [])


    return (
        <DHead title="الرئيسية">
            <div className="row rtl">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="card card-statistic-1">
                        <div className="card-icon bg-primary">
                            <i className="fa-solid fa-users  fa-2x text-white"></i>
                        </div>
                        <div className="card-wrap">
                            <div className="card-header">
                                <h4 className='text-center mb-1'>عدد طلبات هذا الشهر</h4>
                            </div>
                            <div className="card-body text-center">
                                {monthOrders}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="card card-statistic-1">
                        <div className="card-icon bg-warning">
                            <i className="fa-solid fa-glasses fa-2x text-white"></i>
                        </div>
                        <div className="card-wrap">
                            <div className="card-header">
                                <h4 className='text-center mb-1'>عدد الطلبات قيد الدراسة</h4>
                            </div>
                            <div className="card-body text-center">
                                {studyOrders}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="card card-statistic-1">
                        <div className="card-icon bg-success">
                            <i className="fa-solid fa-circle-check fa-2x text-white"></i>

                        </div>
                        <div className="card-wrap">
                            <div className="card-header">
                                <h4 className='text-center mb-1'> الطلبات المقبولة لهذا الشهر</h4>
                            </div>
                            <div className="card-body text-center">
                                {agreedOrders}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="card card-statistic-1">
                        <div className="card-icon bg-danger">
                            <i className="fa-solid fa-triangle-exclamation fa-2x text-white"></i>
                        </div>
                        <div className="card-wrap">
                            <div className="card-header">
                                <h4 className='text-center mb-1'>عدد الطلبات المرفوضة</h4>
                            </div>
                            <div className="card-body text-center">
                                {rejectedOrders}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4>اخر 20 طلب</h4>
                            <div className="card-header-action">
                                <Link to="/orders" className="btn btn-danger">مشاهدة المزيد <i className="fas fa-chevron-right"></i></Link>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <div className="table-responsive table-invoice">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>العميل</th>
                                            <th>بـ تاريخ</th>
                                            <th>المبلغ</th>
                                            <th>النوع</th>
                                            <th>حالة الطلب</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.orders.length === 0 ? <tr><td colSpan={6} className='text-center'>لايوجد اي بيانات</td></tr> : data.orders.map(order => <tr key={order.Id}>
                                            <td>{order.Id}</td>
                                            <td className="font-weight-600">{order.Name}<br />{order.Email}</td>
                                            <td>{DateTimeArabic(order.Date)}</td>
                                            <td>{order.Price}</td>
                                            <td>{order.Type}</td>
                                            <td><div className={`badge badge-${[0, 1, 2].includes(order.Status) ? 'info' : [3, 4, 6, 7].includes(order.Status) ? 'success' : [8, 9].includes(order.Status) ? 'danger' : 'warning'}`}>{orderStatus[order.Status]}</div></td>
                                        </tr>)}
                                    </tbody></table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DHead>
    )
}
