import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table';
// import ReactDOM from 'react-dom';
import $ from 'jquery'
import { Formik } from 'formik';
import BModel from "./BModel"
import { ShallowEqual, consts } from '../utilities/functions';
import iziToast from 'izitoast';
import { useRecoilValue } from 'recoil';
import { userState } from '../utilities/app-store';
iziToast.settings({
    rtl: true
})
// Fnon.Dialogue.Init({ width: 'lg', zIndex: 1999, animation: 'slide-bottom', closeButton: true, showMaxMin: true, defaultBefore, autoFocus: true, autoFocusTag: 'input:not([type="hidden"]):not([disabled]):not([readonly]), select:not([disabled])' });

const Editor = ({ data, isNew, FormContent, schema, url, callback, setShow, editorProps = {} }) => {
    //      contentType: false, // Not to set any content header  
    //      processData: false, // Not to process data 
    return <Formik
        initialValues={data}
        validationSchema={(() => {
            if (typeof schema === 'function')
                return schema(data, isNew);
            else
                return schema
        })()}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
            if (consts.isEditor()) {
                if (!ShallowEqual(values, data)) {
                    let fd = {};
                    fd = { data: values };
                    $.ajax({ url: url + '/Edit', method: 'Post', ...fd }).then(() => {
                        callback(true);
                    }).catch(({ status, responseJSON }) => {
                        if (status === 409 && responseJSON && Array.isArray(responseJSON)) {// Confilict
                            responseJSON.forEach(({ field, errorMsg }) => setFieldError(field, errorMsg));
                        } else {
                            iziToast.error({
                                message: "!لم يتم الحفظ"
                            })
                        }
                        setSubmitting(false);
                    });
                } else {
                    callback(false);
                }
            } else {
                callback(false);
            }
        }}
    >
        {({ handleSubmit, ...etc }) => (
            <BModel title={isNew ? 'جديد' : 'تعديل'} okText="حفظ" handleSubmit={handleSubmit} cancelText="إلغاء" show={true} size="xl" setShow={() => setShow()} {...editorProps}>
                <form onSubmit={handleSubmit} className="row">
                    <FormContent {...etc} handleSubmit={handleSubmit} />
                </form>
            </BModel>
        )}
    </Formik>
}

const GetPrerequisite = (checkFun, url, values) => {
    return new Promise((resolve, reject) => {
        if (!checkFun || checkFun(values)) return resolve();
        // Wait.Bricks('Loading Prerequisite..');
        $.ajax(url + '/prerequisite').then(d => {
            Object.keys(d).forEach(key => consts[key] = d[key]);
            // console.log(d);
            return resolve();
        }).catch(() => {
            return reject()
        })
        // .always(() => Wait.Remove());
    });
}

const Table = ({ title, columns, childFunction, dontEditColumns = false, data, fetchData, loading, tableInfo, schema, FormContent, url, defaultValues, prerequisite, deleteUrl, deletePropToSend = "Id", customEditHandler, ...etc }) => {

    const [editRecord, setEditRecord] = useState(null);
    const handleEdit = (values, isNew = false) => {
        GetPrerequisite(prerequisite, url, values).then(() => {
            setEditRecord(values);
        }).catch((err) => {
            console.log(err);
            iziToast.error({
                message: "تعذر تحميل  بعض المتطلبات ، يرجى تحديث الصفحة والمحاولة مرة أخرى."
            })
        })
    }
    const handleDelete = (id) => {
        // if (!consts.isEditor()) return; // لازم ترجعها عند انشاء الصلاحيات
        iziToast.question({
            timeout: 10000,
            close: false,
            overlay: true,
            displayMode: 'once',
            id: 'question',
            zindex: 999,
            layout: 2,
            rtl: true,
            title: 'سؤال تأكيد',
            message: 'هل انت متأكد من انك تريد الحذف؟',
            position: 'center',
            buttons: [
                ['<button><b>نعم</b></button>', function (instance, toast) {
                    instance.hide({ transitionOut: 'fadeOut' }, toast, true);

                }, true],
                ['<button>إلغاء</button>', function (instance, toast) {
                    instance.hide({ transitionOut: 'fadeOut' }, toast);
                }],
            ],
            onClosing: function (instance, toast, isYes) {
                if (isYes === true) {
                    // Wait.Bricks('Deleting...');
                    $.ajax((deleteUrl ?? url + '/delete') + '?id=' + id,).then((resp) => {
                        if (resp) {
                            iziToast.success({
                                message: "تم الحذف بنجاح"
                            })
                            callFeatch();
                        } else {
                            iziToast.error({
                                message: "حدث خطاء اثناء الحدف او انه لايمكن حذف البيانات كونها مرتبطة بسجلات اخرى"
                            })
                        }
                    }).catch(() => iziToast.error({
                        message: "حدث خطاء اثناء الحدف او انه لايمكن حذف البيانات كونها مرتبطة بسجلات اخرى"
                    }))
                    // .always(() => Wait.Remove())
                }
            }
        });
        // Ask.Danger({
        //     title: 'Confirmation:',
        //     message: 'Are you sure?',
        //     btnOkText: 'Yes, delete',
        //     callback: y => {
        //         if (y) {
        //             Wait.Bricks('Deleting...');
        //             $.ajax((deleteUrl ?? url + '/delete') + '?id=' + id,).then((resp) => {
        //                 if (resp) {
        //                     Hint.Success('Deleted Successfully');
        //                     callFeatch();
        //                 } else {
        //                     Hint.Danger('Unable to delete!.')
        //                 }
        //             }).catch(() => Hint.Danger('Unable to delete!.')).always(() => Wait.Remove())
        //         }
        //     }
        // })
    }
    const modifiedColumns = useMemo(() => {
        if (dontEditColumns) return columns;
        return [...columns, {
            Header: '', disableSortBy: true, accessor: 'Id', Cell: ({ value, row }) => {
                return <>
                    <div className="btn-group float-right">{consts.isEditor() && <button type="button" className="btn btn-sm btn-danger" onClick={() => {
                        handleDelete(row.original[deletePropToSend]);
                    }}>حذف</button>}<button type="button" className="btn btn-sm btn-warning" onClick={() => {
                        handleEdit({ ...row.original })
                    }}>تعديل</button></div>
                </>
            }
        }];
    }, []);
    const serchInput = useRef();
    let sortField = "";
    let isDesc = false;
    const tableInstance = useTable(
        {
            columns: modifiedColumns,
            data,
            initialState: { pageIndex: 0, sortBy: [{ id: 'Id', desc: true }] }, // Pass our hoisted table state
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            // manualPagination: true,
            autoResetPage: false,
            // manualFilters:true,
            manualSortBy: true,
            pageCount: tableInfo.pageCount,

        },
        useSortBy,
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // paginations
        // page,
        canPreviousPage,
        canNextPage,
        // pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize },
    } = tableInstance
    const callFeatch = () => {
        const searchValue = serchInput.current.value ?? " ";
        fetchData({ pageIndex, pageSize, headerGroups, tableInstance, searchValue, sortField, isDesc });
    };
    if (childFunction) {
        childFunction["refresh"] = callFeatch;
    }
    useEffect(() => {
        callFeatch();
        // console.log(pageOptions);
        // fetchData({ pageIndex, pageSize, headerGroups, tableInstance })
    }, [fetchData, pageIndex, pageSize])
    return (
        <>
            <div>
                <div className="content-header mb-4">
                    <h1>{title}</h1>
                </div>
                <div className="card shadow-sm">
                    <div className="card-header">
                        <div>{consts.isEditor() && <span className="btn btn-primary" onClick={() => {
                            if (customEditHandler) {
                                customEditHandler();
                            } else {
                                handleEdit({ ...defaultValues }, true)
                            }
                        }}>جديد</span>}</div>
                        <div className="d-flex justify-content-between align-items-center flex-grow-1">
                            <span>
                                <span className='ml-2'>حجم الجدول :{' '}</span>
                                <select
                                    className="form-control form-control-sm d-inline-block"
                                    value={pageSize}
                                    disabled={loading}
                                    style={{ width: '100px' }}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value))
                                    }}
                                >
                                    {[10, 20, 30, 40, 50, 80, 100].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </span>
                            <span className="ml-auto">
                                <div className="input-group">
                                    <input type="text" className="form-control" disabled={loading} placeholder="البحث" ref={serchInput}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter' && e.currentTarget.value && e.currentTarget.value.trim().length > 0) {
                                                callFeatch();
                                            } else if (e.key === 'Escape' && e.target.value) {
                                                e.target.value = "";
                                                callFeatch();
                                            }
                                        }}
                                    />
                                    <div className="input-group-btn">
                                        <button className="btn btn-primary br-30" onClick={() => {
                                            if (serchInput.current.value && serchInput.current.value.trim().length > 0) {
                                                callFeatch();
                                            }
                                        }}><i className="fas fa-search"></i></button>
                                    </div>
                                </div>
                                {/* البحث :{' '}
                                <input
                                    ref={serchInput}
                                    className="form-control d-inline-block"
                                    disabled={loading}
                                    style={{ width: '150px' }}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter' && e.currentTarget.value && e.currentTarget.value.trim().length > 0) {
                                            callFeatch();
                                        } else if (e.key === 'Escape' && e.target.value) {
                                            e.target.value = "";
                                            callFeatch();
                                        }
                                    }}
                                /> */}
                            </span>
                        </div>

                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-sm table-striped shadow-sm table-hover" {...getTableProps()}>
                                <thead className="bg-primary text-white">
                                    {
                                        headerGroups.map(hg => (
                                            <tr {...hg.getHeaderGroupProps()}>
                                                {hg.headers.map(column => {
                                                    return <th {...column.getHeaderProps(column.getSortByToggleProps())} onClick={() => {
                                                        // column.isSorted=true;
                                                        sortField = column.id;
                                                        isDesc = column.isSortedDesc;
                                                        column.toggleSortBy(!column.isSortedDesc);
                                                        callFeatch();

                                                    }}>
                                                        {/* return <th {...column.getHeaderProps()}> */}
                                                        {column.render('Header')}
                                                        {/* Add a sort direction indicator */}
                                                        <span className="m-2">{column.isSorted
                                                            ? column.isSortedDesc
                                                                ? <i className="fa-solid fa-sort-down"></i>
                                                                : <i className="fa-solid fa-sort-up"></i>
                                                            : !column.disableSortBy && null}</span>
                                                    </th>
                                                })}
                                            </tr>
                                        ))
                                    }
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        {loading ? (
                                            // Use our custom loading state to show a loading indicator
                                            <td colSpan="10000">جاري التحميل...</td>
                                        ) : (
                                            <td colSpan="10000">
                                                <>إظهار {tableInfo.show} من اجمالي {tableInfo.total}{' '}
                                                    البيانات</>
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                            <div className="d-flex">
                                <nav>
                                    <ul className="pagination pagination-primary pagination-sm">
                                        <li className={"page-item " + (!canPreviousPage ? 'disabled' : 'cursor-pointer')} onClick={() => gotoPage(0)}><span className="page-link">
                                            <span><i className="fa-solid fa-angles-left"></i></span>
                                        </span></li>
                                        <li className={"page-item " + (!canPreviousPage ? 'disabled' : 'cursor-pointer')} onClick={() => previousPage()}><span className="page-link">
                                            <span><i className="fas fa-chevron-left"></i></span>
                                        </span></li>
                                        <li className="page-item disabled"><span className="page-link">
                                            <span>
                                                صفحة
                                                {' '}
                                                <strong>
                                                    {pageIndex + 1} من {Math.ceil(tableInfo.total / pageSize)}
                                                </strong>{' '}
                                            </span>
                                        </span></li>

                                        <li className={"page-item " + (!canNextPage ? 'disabled' : 'cursor-pointer')} onClick={() => nextPage()}><span className="page-link">
                                            <span><i className="fas fa-chevron-right"></i></span>
                                        </span></li>
                                        <li className={"page-item " + (!canNextPage ? 'disabled' : 'cursor-pointer')} onClick={() => gotoPage(pageCount - 1)}><span className="page-link">
                                            <span><i className="fa-solid fa-angles-right"></i></span>
                                        </span></li>

                                    </ul>
                                </nav>
                                <span className="mx-2">
                                    إذهب الى صفحة
                                    <input
                                        className="form-control form-control-sm d-inline-block no-spinner mx-2"
                                        disabled={loading}
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter' && e.target.value) {
                                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                                gotoPage(page)
                                            } else if (e.key === 'Escape' && e.target.value) {
                                                gotoPage(1)
                                            }
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {editRecord !== null && <Editor isNew={editRecord.Id === '' || editRecord.Id === 0 || editRecord.Id === null} setShow={() => setEditRecord(null)} data={editRecord} FormContent={FormContent} schema={schema} url={url} callback={(haschanged) => {
                if (haschanged) {
                    iziToast.success({
                        message: "تم الحفظ بنجاح"
                    })
                    callFeatch();
                }
                setEditRecord(null);
            }} {...etc} />}
        </>
    )
}
export default function DefaultTable({ url, children, childFunction, ...etc }) {
    const user = useRecoilValue(userState);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [tableInfo, setTableInfo] = useState({
        total: 0,
        pageCount: 0
    })

    const fetchIdRef = useRef(0)

    const fetchData = useCallback(({ pageIndex, pageSize, searchValue, sortField, isDesc }) => {
        const fetchId = ++fetchIdRef.current
        if (fetchId === fetchIdRef.current) {
            setLoading(true)
            $.ajax({ url: url + '/get', data: { sortField, isDesc, pageSize, pageIndex, searchValue } }).then((resp) => {
                const { total, data, showing } = resp;
                setData(data);
                setTableInfo({
                    total,
                    pageCount: Math.ceil(total / pageSize),
                    show: showing
                });
                if (childFunction && childFunction.tableChanged) {
                    childFunction.tableChanged()
                }
            }).catch(console.log).always(() => {
                setLoading(false);
            });
        }
    }, [])
    if (childFunction) {
        childFunction['setData'] = setData;
    }
    if (!user) return null;
    return (
        <>
            <Table
                url={url}
                {...etc}
                fetchData={fetchData}
                data={data}
                loading={loading}
                tableInfo={tableInfo}
                childFunction={childFunction}

            />
            {children}
        </>
    )
}