// import React, { useEffect, useState } from 'react'
// import DHead from "../components/DHead"
// import $ from "jquery"
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import "./Dashboard.css"
// import * as Yup from "yup"

// import { Swiper, SwiperSlide } from "swiper/react";
// // import required modules
// import { Autoplay, Pagination, Navigation } from "swiper";
// import BModel from './../components/BModel';
// import LoadingSpinner from '../components/LoadingSpinner';
// import { consts } from '../utilities/functions';
import { useRecoilValue } from 'recoil';
import { userState } from '../utilities/app-store';
// import iziToast from 'izitoast';
// import { requiredIdNumber, requiredPhoneNumber } from '../utilities/validations';
// import { Formik } from 'formik';
// import FormInputWithIcon from '../components/FormInputWithIcon';
// import FormInput from '../components/FormInput';
// import IBANInput from '../components/IBANInput';
// import { getBankByIBAN, validateIBAN } from '../utilities/validateIBAN';
// import FormSelect from '../components/FormSelect';



// const style = { height: "300px" }
// function ClientDashboard() {
//     const [data, setData] = useState([])
//     const [add, setAdd] = useState(null);
//     useEffect(() => {
//         $.ajax({
//             url: "/api/dashboard/data"
//         }).then(resp => {
//             setData(resp)
//         }).always(() => {

//         })
//     }, [])

//     return (
//         <>
//             <DHead title="الرئيسية">
//                 <div className="row">
//                     <div className="col-12 mb-4">
//                         <Swiper
//                             spaceBetween={30}
//                             centeredSlides={true}
//                             autoplay={{
//                                 delay: 2500,
//                                 disableOnInteraction: false,
//                             }}
//                             pagination={{
//                                 clickable: true,
//                             }}
//                             navigation={true}
//                             modules={[Autoplay, Pagination, Navigation]}
//                             className="mySwiper"
//                         >
//                             {data.filter(c => c.IsSlide).map(item =>
//                                 <SwiperSlide key={item.Id} onClick={() => setAdd(item)}>
//                                     <img src={`/content/${item.Photo}`} />
//                                     <div className='description'>
//                                         <h1>{item.Name}</h1>
//                                         {/* <h5>{item.Description}</h5> */}
//                                         <p>تمويل لماتصل قيمتة</p>
//                                         <div className='price'>
//                                             {item.Price} SAR
//                                         </div>
//                                     </div>

//                                 </SwiperSlide>)}
//                         </Swiper>
//                     </div>
//                     {data.filter(c => !c.IsSlide).map(item => (
//                         <div className="col-12 col-md-4 col-lg-4" key={item.Id}>
//                             <article className="article article-style-c rtl hover-effect" onClick={() => setAdd(item)}>
//                                 <div className="article-header">
//                                     <div className="article-image" style={{ backgroundImage: `url('/content/${item.Photo}')` }}>
//                                     </div>
//                                 </div>
//                                 <div className="article-details">
//                                     <div className="article-category">بـ <span>{item.Price} ريال</span></div>
//                                     {/* <div className="article-category"><a href="#">News</a> <div className="bullet"></div> <a href="#">5 Days</a></div> */}
//                                     <div className="article-title">
//                                         <h5 className='text-primary'>{item.Name}</h5>
//                                     </div>
//                                     <p>{item.Description}</p>
//                                 </div>
//                             </article>
//                         </div>
//                     ))}
//                 </div>
//             </DHead>
//             {add && <AddOrderModal show={add} setShow={setAdd} />}
//         </>
//     )
// }

// export default () => {
//     const user = useRecoilValue(userState);
//     if (!user) return null;
//     if(user?.roles[0]==="Client") return <ClientDashboard />;

// }

// const addModelValidation = Yup.object().shape({
//     OrderTypeId: Yup.number().required("يجب إختيار نوع قيمة التمويل"),
//     // IdentityNumber: requiredIdNumber,
//     IBANNumber: Yup.string().required("رقم الـ IBAN لايمكن ان يكون فارغاً").test("is-valid", "لايبدو الرقم صالح", value => validateIBAN(value)),
//     BankName: Yup.string().required("يجب تسجيل اسم البنك"),
//     PhoneNumber: requiredPhoneNumber,
//     // Address:Yup.string().required(""),
//     // City:Yup.string().required(""),
// })

// const AddOrderModal = ({ show, setShow }) => {
//     // const setLoading = useSetRecoilState(loadingState)
//     const [userData, setUserData] = useState(null)
//     const [oT, setOT] = useState([]);
//     useEffect(() => {
//         $.ajax("/api/order/getclientinfo").then(({ client, types }) => {
//             setUserData(client);
//             setOT(types);
//         }).catch(() => {
//             setShow(null);
//             iziToast.warning({
//                 message: "لم نستطع جلب بعض البيانات. الرجاء التأكد من اتصال الانترنت والمحاولة لاحقا"
//             })
//         }).always(() => {
//             // setLoading(false)
//         })
//     }, [])
//     if (!userData) {
//         // setLoading(true);
//         return null;
//     }
//     return (
//         <Formik
//             validationSchema={addModelValidation}
//             initialValues={(() => {
//                 return JSON.parse(JSON.stringify(userData))
//             })()}
//             onSubmit={(values, { setSubmitting }) => {
//                 $.ajax({
//                     url: "/api/order/placeclientorder",
//                     method: "POST",
//                     data: values
//                 }).then(() => {
//                     iziToast.success({
//                         message: "تم إنشاء الطلب بنجاح. سوف يتم مراجعة الطلب والتواصل معك إن احتجنا اي معلومات إضافية.",
//                         position: "center"
//                     })
//                     setShow(null);
//                 }).catch(() => {
//                     iziToast.error({
//                         message: "لم نستطع إنشاء هذا الطلب. الرجاء المحاولة لاحقاً"
//                     })
//                 }).always(() => {
//                     setSubmitting(false);
//                 })
//             }}
//         >
//             {({ handleSubmit, errors, values }) => (
//                 <BModel show={show} setShow={() => setShow(null)} title="طلب تمويل جديد" okText="قدم الطلب" cancelText="إلغاء" handleSubmit={handleSubmit} >
//                     {/* <p className='text-center font-weight-bold mb-0'>طلب تمويل اصغر بقيمة {show.Price} ريال سعودي</p> */}
//                     <p className='text-center text-danger'>الرجاء التأكد من صحة المعلومات التالية من ثم قم بتقديم الطلب</p>
//                     <div className="row">
//                         <FormSelect containerClassName="col-md-6" propName="OrderTypeId" required title="قيمة التمويل">
//                             <option></option>
//                             {oT.map(o => <option key={o.Id} value={o.Id}>{o.Name}</option>)}
//                         </FormSelect>
//                         <div className="col-12"></div>
//                         <FormInputWithIcon title="رقم الهاتف" maxLength={9} required className="no-spinner text-left" containerClassName="col-md-6" prepend="+966" propName="PhoneNumber" disabled />
//                         <FormInput title="رقم الهوية | الإقامة" disabled propName="IdentityNumber" containerClassName="col-md-6" maxLength={10} required />
//                         <IBANInput containerClassName="col-md-6" required />
//                         <FormInput title="إسم البنك" propName="BankName" required containerClassName="col-md-6" maxLength={200}>
//                             <ValidateBankName errors={errors} values={values} />
//                         </FormInput>
//                         <FormInput title="العنوان" propName="Address" maxLength={150} containerClassName="col-md-6" />
//                         <FormInput title="المدينة" propName="City" containerClassName="col-md-6" maxLength={70} />
//                         <FormInput title="المهنة" propName="Occupation" containerClassName="col-md-6" maxLength={200} />
//                         <FormInput title="جهة العمل" propName="WorkPlace" containerClassName="col-md-6" maxLength={200} />

//                     </div>
//                 </BModel>
//             )}
//         </Formik>
//     )
// }

// const ValidateBankName = ({ errors, values }) => {
//     if (errors["BankName"] || errors["IBANNumber"] || !values.IBANNumber) return null;
//     // get bank info
//     const b = getBankByIBAN(values.IBANNumber);
//     if (b && b.nameAr != values.BankName) {
//         return <span className='d-block mt-1 small text-warning'>بناء على رقم الـ IBAN فأسم البنك يجب ان يكون  <b className='text-primary'>{b.nameAr}</b></span>
//     }
//     return null;
// }
import React from 'react'
import AdminDashboard from './AdminDashboard';
import ClientDashboard from './ClientDashboard';

export default function Dashboard() {
    const user = useRecoilValue(userState);
    if (!user) return null;
    if (user?.roles[0] === "Client") return <ClientDashboard />
    return <AdminDashboard />
}
